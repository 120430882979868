import { useEffect, useState } from "react";
import { useToast } from "../../components/Toast";
import LoginForm from "./LoginForm/LoginForm";
import VideoBackground from "./VideoBackground/VideoBackground";
import WelcomeMessage from "./WelcomeMessage/WelcomeMessage";
import { signIn } from "../../slices/user.slice";
import { useDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";

const Home = () => {
  const { Toast, setToast } = useToast("home-toast");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {}, []);

  const handleSignIn = async (username: string, password: string) => {
    setIsLoading(true);
    dispatch(signIn({ username, password }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        goToProfile();
      })
      .catch((error) => {
        setIsLoading(false);
        setToast({
          title:
            "Tus datos no son correctos. ¿Tenés cuenta? Intentalo nuevamente.",
          theme: "error",
        });
      });
  };

  const goToProfile = () => {
    navigate("/perfil");
  };

  return (
    <>
      <VideoBackground />
      <div className="relative flex flex-col gap-x-10 gap-y-8 md:flex-row w-full pt-20 md:pt-0 md:justify-center h-[100vh] items-center z-50 p-10 lg:px-20 lg:py-32 top-0">
        <div className="w-full md:w-1/3 lg:w-1/2">
          <WelcomeMessage />
        </div>
        <div className="w-full md:w-2/3 lg:w-1/2">
          <LoginForm handleSignIn={handleSignIn} isLoading={isLoading} />
        </div>
        <Footer fixed={true}/>
        {Toast}
      </div>
    </>
  );
};

export default Home;
