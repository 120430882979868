import httpClient from "../lib/httpClient";
import { LoginReponse, User, UserFansId, UserFansIdResponse } from "../types";
import { AxiosRequestConfig } from "axios";

export const api = {
  signIn: async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    const { data } = await httpClient("", false).post<LoginReponse>(
      `/authentication/logins`,
      {
        identifier: username,
        password,
      }
    );
    return data;
  },
  getUser: async ({
    userId,
    config,
  }: {
    userId: string;
    config: AxiosRequestConfig;
  }) => {
    const { data } = await httpClient().get<Partial<User>>(
      `/users/${userId}/complete`,
      config
    );
    return data;
  },
  getUserFansId: async ({
    userId,
    config,
  }: {
    userId: string;
    config: AxiosRequestConfig;
  }) => {
    const { data } = await httpClient().get<UserFansIdResponse>(
      `/users/${userId}/fansid`,
      config
    );
    return data;
  },
  createUserFansId: async ({
    userId,
    userFansId,
    config,
  }: {
    userId: string;
    userFansId: UserFansId;
    config: AxiosRequestConfig;
  }) => {
    const { data } = await httpClient().post<UserFansIdResponse>(
      `/users/${userId}/fansids`,
      userFansId,
      config
    );

    return data;
  },
  updateUserFansId: async ({
    userId,
    userFansId,
    config,
  }: {
    userId: string;
    userFansId: Partial<UserFansId>;
    config: AxiosRequestConfig;
  }) => {
    const { data } = await httpClient().patch<UserFansIdResponse>(
      `/users/${userId}/fansid`,
      userFansId,
      config
    );

    return data;
  },
  loadUserAvatar: async ({
    userId,
    avatar,
    config,
  }: {
    userId: string;
    avatar: File;
    config: AxiosRequestConfig;
  }) => {
    const formData = new FormData();
    formData.append("media", avatar);

    const { data } = await httpClient().post<UserFansIdResponse>(
      `/users/${userId}/media`,
      formData,
      config
    );

    return data;
  },
  createPlayerMedicalRecord: async ({
    userId,
    playerId,
    medicalRecord,
    config,
  }: {
    userId: string;
    playerId: string;
    medicalRecord: File;
    config: AxiosRequestConfig;
  }) => {
    const formData = new FormData();
    formData.append("medicalRecord", medicalRecord);

    await httpClient().post(
      `/users/${userId}/players/${playerId}/medicalrecords`,
      formData,
      config
    );
  },
  deletePlayerMedicalRecord: async ({
    userId,
    playerId,
    config,
  }: {
    userId: string;
    playerId: string;
    config: AxiosRequestConfig;
  }) => {
    await httpClient().delete(
      `/users/${userId}/players/${playerId}/medicalrecord`,
      config
    );
  },
  updatePlayer: async ({
    userId,
    playerId,
    medicalRecordExp,
    config,
  }: {
    userId: string;
    playerId: string;
    medicalRecordExp: string;
    config: AxiosRequestConfig;
  }) => {
    await httpClient().patch(
      `/users/${userId}/players/${playerId}`,
      { medicalRecordExp },
      config
    );
  },
};
