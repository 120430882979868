const VideoBackground = () => {
  return (
    <>
      <video
        autoPlay
        loop
        muted
        className="absolute z-10 w-auto min-w-full min-h-full max-w-full object-cover"
      >
        <source src="assets/video/home-background-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute z-20 w-auto min-w-full min-h-full max-w-full bg-bg-default opacity-20"></div>
    </>
  );
};

export default VideoBackground;
