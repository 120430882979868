import { LoginUserResponse, User, UserFansIdResponse } from "../types";

const loginUserResponseToUser = (loginUserResponse: LoginUserResponse): User => {
  return {
    id: loginUserResponse.id,
    username: loginUserResponse.username,
    firstName: loginUserResponse.firstName,
    lastName: loginUserResponse.lastName,
    hasFansIdConfigurated: false,
  }
}

const userFansIdResponseToUser =  (userFansIdResponse: UserFansIdResponse): Partial<User> => {
  return {
    phone: userFansIdResponse.phone,
    birthday: userFansIdResponse.birthday,
    document: userFansIdResponse.document,
    gender: userFansIdResponse.gender,
    height: userFansIdResponse.height,
    weight: userFansIdResponse.weight,
    skillfulFoot: userFansIdResponse.skillfulFoot,
    sport: userFansIdResponse.sport,
    position: userFansIdResponse.position,
    nationality: userFansIdResponse.nationality,
    hasFansIdConfigurated: userFansIdResponse.hasFansIdConfigurated,
  }
}

export default { loginUserResponseToUser, userFansIdResponseToUser }