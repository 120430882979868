import { useEffect } from "react";
import RtDatepicker from "react-tailwindcss-datepicker";
import "./Datepicker.css";
import classNames from "classnames";

interface DatepickerProps {
  className?: string;
  value: any;
  onChange: (value: any, e?: HTMLInputElement | null | undefined) => void;
  inputClassName?: string;
  variant: "primary" | "secondary";
  direction?: "up" | "down"
}

const Datepicker = ({
  className,
  value,
  onChange,
  inputClassName,
  variant,
  direction = "down"
}: DatepickerProps) => {
  const classes = classNames(
    "datepicker-custom",
    { "datepicker-custom-secondary": variant === "secondary" },
    className
  );

  const inputClasses = classNames({
    "text-center pr-5 text-xl py-4": !inputClassName,
    [inputClassName ?? ""]: !!inputClassName,
  });

  useEffect(() => {
    document.getElementById("datepicker")!.inputMode = "none";
  }, []);

  return (
    <RtDatepicker
      containerClassName={classNames(
        "relative w-full text-white-pure",
        classes
      )}
      inputClassName={classNames(
        "relative transition-all duration-300 py-2.5 pl-4 pr-14 w-full rounded-lg tracking-wide text-sm focus:border focus:border-secondary !focus-visible:border-secondary focus-visible:border outline-0 placeholder-gray-400 disabled:opacity-40 disabled:cursor-not-allowed",
        inputClasses
      )}
      primaryColor={"emerald"}
      i18n={"es-mx"}
      useRange={false}
      asSingle={true}
      value={value}
      onChange={onChange}
      displayFormat={"DD/MM/YYYY"}
      inputId="datepicker"
      popoverDirection={direction}
    />
  );
};

Datepicker.defaultProps = {
  variant: "primary",
};

export default Datepicker;
