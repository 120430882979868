import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import cn from 'classnames';

export type ErrorBoxProps = {
  message: string;
};

const ErrorBox = ({ message }: ErrorBoxProps) => {
  return (
    <div
      className={cn(
        "flex flex-row gap-2 justify-start items-center px-2 py-1 text-medium text-left font-semibold text-white-pure sm:min-h-[32px] bg-error mt-2 min-h-[38px]"
      )}
    >
      <div className="w-[14px] h-[14px]">
        <ExclamationTriangleIcon className="text-white-pure w-full h-full" />
      </div>
      <div className="w-[calc(100%-14px)]">{message}</div>
    </div>
  );
};

export default ErrorBox;
