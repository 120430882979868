import { useInput } from "../../../../components/Input";
import { UniselectOption } from "../../../../components/UniselectMenu";
import { SPORTS } from "../../../../constants";
import Button from "../../../../components/Button";
import UniselectDropdown from "../../../../components/UniselectDropdown";
import ErrorBox from "../../../../components/ErrorBox";
import { useState } from "react";
import { UserFansId } from "../../../../types";

export type SportStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  sportValue?: string;
};

const SportStep = ({
  title,
  sportValue: SportInitialValue,
  setStepValues,
}: SportStepProps) => {
  const [hasError, setHasError] = useState(false);
  const sportValue = useInput<string | undefined>({
    value: SportInitialValue,
  });

  const sports: UniselectOption<string>[] = SPORTS.map((sport) => {
    return {
      value: sport.id,
      label: sport.title,
    };
  });

  const onContinueHandler = () => {
    if (!!sportValue.value) {
      setStepValues({
        sport: sportValue.value,
      });
    } else {
      setHasError(true);
    }
  };

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular mb-6">{title}</h1>
      <UniselectDropdown
        id="position-dropdown"
        label=""
        value={sportValue.value}
        options={sports}
        onChange={(value) => {
          setHasError(!value);
          sportValue.onChange(value);
        }}
        hasChanged={sportValue.hasChanged}
        hasError={sportValue.hasError}
        errorMsg={sportValue.errorMsg}
      />
      {hasError && (
        <div className="w-full">
          <ErrorBox message="Debe seleccionar una opción" />
        </div>
      )}
      <div className="flex justify-center items-center mt-16">
        <Button id={"button-position-step"} width="fit" onClick={onContinueHandler}>
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default SportStep;
