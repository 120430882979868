import { FootprintLeftIcon, FootprintRightIcon } from "../components/icons";
import { PhoneCode, PlayerPosition, SkillfullFootOption, Sport } from "../types";

export const GENDER_TYPE_MALE_ID = "M";
export const GENDER_TYPE_FEMALE_ID = "F";

export const SPORTS: Sport[] = [
  {
    id: "futbol-5-6",
    title: "Futbol 5\/6",
  },
  {
    id: "futbol-7-8",
    title: "Futbol 7\/8",
  },
  {
    id: "futbol-9",
    title: "Futbol 9",
  },
  {
    id: "futbol-11",
    title: "Futbol 11",
  },
  {
    id: "Handball",
    title: "Handball",
  },
  {
    id: "Hockey",
    title: "Hockey",
  },
];

export const PLAYER_POSITIONS: PlayerPosition[] = [
  {
    id: "dt",
    title: "DT",
    imageName: "dt-position.png",
  },
  {
    id: "goalkeeper-position",
    title: "Arquero",
    imageName: "goalkeeper-position.png",
  },
  {
    id: "left-wingback-position",
    title: "Lateral izquierdo",
    imageName: "left-wingback-position.png",
  },
  {
    id: "center-defense-position",
    title: "Defensa central",
    imageName: "center-defense-position.png",
  },
  {
    id: "right-wingback-position",
    title: "Lateral derecho",
    imageName: "right-wingback-position.png",
  },
  {
    id: "left-midfielder-position",
    title: "Volante izquierdo",
    imageName: "left-midfielder-position.png",
  },
  {
    id: "center-midfielder-position",
    title: "Volante central",
    imageName: "center-midfielder-position.png",
  },
  {
    id: "right-midfielder-position",
    title: "Volante derecho",
    imageName: "right-midfielder-position.png",
  },
  {
    id: "attacking-midfielder-position",
    title: "Enganche",
    imageName: "attacking-midfielder-position.png",
  },
  {
    id: "left-forward-position",
    title: "Extremo izquierdo",
    imageName: "left-forward-position.png",
  },
  {
    id: "right-forward-position",
    title: "Extremo derecho",
    imageName: "right-forward-position.png",
  },
  {
    id: "center-forward-position",
    title: "Delantero centro",
    imageName: "center-forward-position.png",
  },
];


export const SKILLFULL_FOOT_OPTIONS: SkillfullFootOption[] = [
  {
    id: 'I',
    title: 'Izquierdo',
    icon: FootprintLeftIcon as React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>,
  },
  {
    id: 'D',
    title: 'Derecho',
    icon: FootprintRightIcon as React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>
  }
];

export const PHONE_CODE_OPTIONS: PhoneCode[] = [
  {
    id: '+54',
    value: '🇦🇷 +54',
    country: 'AR'
  },
  {
    id: '+591',
    value: '🇧🇴 +591',
    country: 'BO'
  },
  {
    id: '+55',
    value: '🇧🇷 +55',
    country: 'BR'
  },
  {
    id: '+56',
    value: '🇨🇱 +56',
    country: 'CL'
  },
  {
    id: '+57',
    value: '🇨🇴 +57',
    country: 'CO'
  },
  {
    id: '+593',
    value: '🇪🇨 +593',
    country: 'EC'
  },
  {
    id: '+34',
    value: '🇪🇸 +34',
    country: 'ES'
  },
  {
    id: '+592',
    value: '🇬🇾 +592',
    country: 'GY'
  },
  {
    id: '+594',
    value: '🇬🇫 +594',
    country: 'GF'
  },
  {
    id: '+595',
    value: '🇵🇾 +595',
    country: 'PY'
  },
  {
    id: '+51',
    value: '🇵🇪 +51',
    country: 'PE'
  },
  {
    id: '+597',
    value: '🇸🇷 +597',
    country: 'SR'
  },
  {
    id: '+598',
    value: '🇺🇾 +598',
    country: 'UY'
  },
  {
    id: '+58',
    value: '🇻🇪 +58',
    country: 'VE'
  },
]

