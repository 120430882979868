import { useMemo } from "react";
import { PLAYER_POSITIONS, SPORTS } from "../../../../constants";
import { useInput } from "../../../../components/Input";
import { UniselectOption } from "../../../../components/UniselectMenu";
import UniselectDropdown from "../../../../components/UniselectDropdown";
import { UserFansId } from "../../../../types";

export type PositionDataProps = {
  sportId: string;
  positionId: string;
  isEditMode: boolean;
  onChangeData: (value: Partial<UserFansId>) => void;
};

const PositionData = ({
  sportId,
  positionId,
  isEditMode,
  onChangeData,
}: PositionDataProps) => {
  const sportInputValue = useInput<string | undefined>({
    value: sportId,
  });

  const sports: UniselectOption<string>[] = SPORTS.map((sport) => {
    return {
      value: sport.id,
      label: sport.title,
    };
  });

  const positionInputValue = useInput<string | undefined>({
    value: positionId,
  });

  const positions: UniselectOption<string>[] = PLAYER_POSITIONS.map(
    (position) => {
      return {
        value: position.id,
        label: position.title,
      };
    }
  );

  const positionSelected = useMemo(() => {
    return PLAYER_POSITIONS.find((position) => position.id === positionId);
  }, [positionId]);

  const sportSelected = useMemo(() => {
    return SPORTS.find((sport) => sport.id === sportId);
  }, [sportId]);

  return (
    <div className="flex flex-col bg-bg-level-1 p-8 w-full rounded-md gap-y-8">
      <h1 className="text-regular font-bold text-white-pure text-left">
        Posición
      </h1>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col w-full gap-2">
          {isEditMode ? (
            <UniselectDropdown
              id="position-dropdown"
              label=""
              value={sportInputValue.value}
              options={sports}
              onChange={(value) => {
                onChangeData({ sport: value });
                sportInputValue.onChange(value);
              }}
              hasChanged={sportInputValue.hasChanged}
              hasError={sportInputValue.hasError}
              errorMsg={sportInputValue.errorMsg}
            />
          ) : (
            <div className="flex flex-col w-full p-4 border border-secondary rounded-md justify-center items-start">
              <span className="text-regular text-white-pure font-bold">
                {sportSelected?.title}
              </span>
            </div>
          )}
          {isEditMode ? (
            <UniselectDropdown
              id="position-dropdown"
              label=""
              value={positionInputValue.value}
              options={positions}
              onChange={(value) => {
                onChangeData({ position: value });
                positionInputValue.onChange(value);
              }}
              hasChanged={positionInputValue.hasChanged}
              hasError={positionInputValue.hasError}
              errorMsg={positionInputValue.errorMsg}
            />
          ) : (
            <div className="flex flex-col w-full p-4 border border-secondary rounded-md justify-center items-start">
              <span className="text-regular text-white-pure font-bold">
                {positionSelected?.title}
              </span>
            </div>
          )}
        </div>
        <div className="flex justify-center items-start mt-4 lg:-mt-3">
          <img
            width={400}
            height={300}
            src={`/assets/images/${positionSelected?.imageName}`}
            alt="position-image"
          />
        </div>
      </div>
    </div>
  );
};

export default PositionData;
