import { useMediaQuery } from "react-responsive";

const breakpoints = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xxl:"1440px",
  xxxl: "1920px"
} as {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  xxxl:string;
};

type BreakpointKey = keyof typeof breakpoints;

function useBreakpoint<K extends BreakpointKey>(
  breakpointKey: K
): Record<`is${Capitalize<K>}`, boolean> {
  const bool = useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`,
  });
  const capitalizedKey =
    breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  type Key = `is${Capitalize<K>}`;
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>;
}

export default useBreakpoint;
