import { useWindowDimensions } from '../../hooks';

export type ModalBackgroundProps = {
  id: string;
  children: JSX.Element;
};

const ModalBackground = ({
  id,
  children,
}: ModalBackgroundProps): JSX.Element => {
  const { height } = useWindowDimensions();

  return (
    <div
      id={`modal-background-${id}`}
      data-testid={`modal-background-${id}`}
      className='box-border w-screen fixed top-0 left-0 z-50 flex justify-center items-center bg-bg-default bg-opacity-75'
      style={{ height }}
    >
      {children}
    </div>
  );
};

export default ModalBackground;
