import { clearNumericValues } from "./clear-numeric-value";
import { stringGenericFormat } from "./generic-formatter";

const DOCUMENT_GAPS: Record<string, number[]> = {
  ["6"]: [3],
  ["7"]: [1, 4],
  ["8"]: [2, 5],
};

export const documentFormatter = (document: string): string => {
  const gaps = DOCUMENT_GAPS[document.length.toString()];
  const isNumeric = clearNumericValues(document).length === document.length;

  if (!!gaps && isNumeric) {
    return stringGenericFormat(document, gaps, ".");
  }

  return document;
};
