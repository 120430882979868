import { useState } from "react";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import { SearchCountry } from "../../../../../components/SearchCountry";

export type EditNationalityModalProps = {
  nationality: string;
  onConfirm: (nationalityId: string) => void;
  onClose: () => void;
};

const EditNationalityModal = ({
  onClose,
  onConfirm,
  nationality,
}: EditNationalityModalProps) => {
  const [selectedNationality, setSelectedNationality] = useState(
    nationality || ""
  );

  return (
    <Modal
      id={"edit-nationality-modal"}
      onClose={onClose}
      body={
        <div className="flex flex-col items-center justify-center w-full p-4 gap-y-6">
          <SearchCountry
            initialValue={selectedNationality}
            onSelected={(value) => {
              setSelectedNationality(value);
            }}
          />
          <div className="flex justify-center items-center mt-16">
            <Button
              id={"button-edit-nationality-confirm"}
              width="fit"
              onClick={() => onConfirm(selectedNationality)}
            >
              Continuar
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default EditNationalityModal;
