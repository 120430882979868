import { useMemo } from "react";
import Step, { StepMetadata } from "./Step";
import { useBreakpoint } from "../../hooks";
import cn from "classnames";

export type StepperProps = {
  blockNavigation: boolean;
  stepsMetadata: StepMetadata[];
  completedSteps: StepMetadata["id"][];
  selectedStep: StepMetadata["id"];
  onSelectStep: (stepId: StepMetadata["id"]) => void;
};

const Stepper = ({
  blockNavigation,
  stepsMetadata,
  completedSteps,
  selectedStep,
  onSelectStep,
}: StepperProps) => {
  const { isLg } = useBreakpoint("lg");

  const steps = useMemo(() => {
    return stepsMetadata.map((stepMetadata, index) => {
      const isCompletedStep = completedSteps.includes(stepMetadata.id);
      return (
        <Step
          key={`step-${stepMetadata.id}`}
          id={stepMetadata.id}
          title={
            isLg
              ? stepMetadata.desktopLabel
              : stepMetadata.mobileLabel || stepMetadata.desktopLabel
          }
          isSelected={selectedStep === stepMetadata.id}
          customClasses={cn({
            "border-l-[1px] border-y-[1px] border-secondary !text-secondary":
              isCompletedStep && !(selectedStep === stepMetadata.id),
            "border-l-[1px] border-y-[1px] border-white-pure":
              !isCompletedStep && stepMetadata.id > selectedStep,
            "rounded-l-md": index === 0,
            "rounded-r-md border-r-[1px] border-white-pure":
              !isCompletedStep && index === stepsMetadata.length - 1,
            "rounded-r-md border-r-[1px] border-secondary":
              isCompletedStep && index === stepsMetadata.length - 1,
          })}
          onSelect={(stepId: number) => {
            if (
              stepId === 1 ||
              (completedSteps.includes(stepId - 1) && !blockNavigation)
            ) {
              onSelectStep(stepId);
            }
          }}
        />
      );
    });
  }, [stepsMetadata, selectedStep, isLg, completedSteps]);

  return (
    <div className="flex flex-row w-full justify-center items-center">
      {steps}
    </div>
  );
};

export default Stepper;
