import axios from 'axios';

export const fileDownloader = async (fileUrl: string, fileName: string): Promise<void> => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: 'blob',
    });

    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName || 'documento.pdf';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error('Error al descargar el archivo:', error);
  }
}