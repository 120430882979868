import { PHONE_CODE_OPTIONS } from "../constants";

export const splitPhoneNumber = (
  phone: string
): { countryCode: string; phone: string } => {
  const contryCode = PHONE_CODE_OPTIONS.find(option => phone.startsWith(option.id));
  const phoneFormatted = contryCode ? phone.substring(contryCode?.id.length): phone;

  return {
    countryCode: contryCode?.id || '',
    phone: phoneFormatted,
  }
};
