import { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import Input, { useInput } from "../../../../components/Input";
import { UserFansId } from "../../../../types";
import { clearNumericValues } from "../../../../utils/clear-numeric-value";
import { UniselectOption } from "../../../../components/UniselectMenu";
import { PHONE_CODE_OPTIONS } from "../../../../constants";
import UniselectDropdown from "../../../../components/UniselectDropdown";
import ErrorBox from "../../../../components/ErrorBox";

export type ProfileStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  documentValue?: string;
  phoneValue?: string;
  countryCodeValue?: string;
  stepErrorHandler: (hasError: boolean) => void;
};

const ProfileStep = ({
  title,
  setStepValues,
  stepErrorHandler,
  documentValue,
  phoneValue,
  countryCodeValue,
}: ProfileStepProps) => {
  const [hasError, setHasError] = useState(false);
  const document = useInput<string>({
    value: documentValue || "",
    emptyValue: "",
    validateError: (value) => [value.length < 1, "Es un campo requerido"],
  });

  const phone = useInput<string>({
    value: countryCodeValue || "",
    emptyValue: "",
    validateError: (value) => [value.length < 1, "Es un campo requerido"],
  });

  const phoneCountryCode = useInput<string | undefined>({
    value: phoneValue,
  });

  const phoneCountryCodeOptions: UniselectOption<string>[] =
    PHONE_CODE_OPTIONS.map((option) => {
      return {
        value: option.id,
        label: option.value,
      };
    });

  const onContinueHandler = () => {
    if (!document.value) {
      document.setHasChanged(true);
      document.validateInput();
    }

    if (!phoneCountryCode.value) {
      setHasError(true);
      phoneCountryCode.setHasChanged(true);
      phoneCountryCode.validateInput();
    }

    if (!phone.value) {
      phone.setHasChanged(true);
      phone.validateInput();
    }

    if (!document.hasError && !phoneCountryCode.hasError && !phone.hasError) {
      setStepValues({
        document: document.value,
        phone: `${phoneCountryCode.value}${phone.value}`,
      });
    }
  };

  useEffect(() => {
    stepErrorHandler(
      phone.hasError || phoneCountryCode.hasError || document.hasError
    );
  }, [phone.hasError, phoneCountryCode.hasError, document.hasError]);

  const onChangeStringNumericInputBase = (
    value: string,
    valueMaxLength: number,
    inputOnChange: (value: string) => void
  ) => {
    const _value = clearNumericValues(value);

    if (_value.length > valueMaxLength) return;

    if (!_value.length) {
      inputOnChange("");
      return;
    }

    inputOnChange(_value);
  };

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular">{title}</h1>
      <div className="flex flex-col gap-y-4 w-full mt-8">
        <Input
          {...document}
          id={"document"}
          name={"document"}
          label={"Documento *"}
          type={"string"}
          placeholder={"DNI / ID"}
          onChange={(e) => document.onChange(e.target.value)}
        />
        <div className="flex flex-row gap-x-2">
          <div className="w-1/3">
            <UniselectDropdown
              id="skillful-foot-dropdown"
              label="Teléfono *"
              value={phoneCountryCode.value}
              options={phoneCountryCodeOptions}
              onChange={(value) => {
                setHasError(!value);
                phoneCountryCode.onChange(value);
              }}
              hasChanged={phoneCountryCode.hasChanged}
              hasError={phoneCountryCode.hasError}
              errorMsg={phoneCountryCode.errorMsg}
            />
            {hasError && (
              <div className="w-full">
                <ErrorBox message="Es un campo requerido" />
              </div>
            )}
          </div>
          <div className="w-2/3 flex mt-5">
            <Input
              {...phone}
              id={"phone"}
              name={"phone"}
              type={"string"}
              placeholder="Teléfono"
              onChange={(e) =>
                onChangeStringNumericInputBase(
                  e.target.value,
                  25,
                  phone.onChange
                )
              }
            />
          </div>
        </div>
        <div className="flex justify-center items-center mt-16">
          <Button
            id={"button-profile-step"}
            width="fit"
            onClick={onContinueHandler}
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileStep;
