import { useEffect, useMemo, useState } from "react";
import { RootState, useDispatch } from "../../store";
import { userFansId, getUser, updateUserFansId } from "../../slices/user.slice";
import { useSelector } from "react-redux";
import getAuthHeaders from "../../utils/get-auth-headers";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import {
  MedicalRecordData,
  PersonalData,
  PhysicalData,
  PositionData,
  SkillfulFootData,
} from "./Sections";
import { api as countryApi } from "../../services/country.services";
import { Country, UserFansId } from "../../types";
import Footer from "../../components/Footer";
import { useToast } from "../../components/Toast";
import { splitPhoneNumber } from "../../utils/split-phone-and-contry-code";
import { useBreakpoint } from "../../hooks";
import cn from 'classnames';

const Profile = () => {
  const { Toast, setToast } = useToast("profile-toast");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isXxl } = useBreakpoint('xxl');
  const [isFetchingFansId, setIsFetchingFansId] = useState(true);
  const [isErrorFetchingFansId, setIsErrorFetchingFansId] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const [isErrorFetchingUser, setIsErrorFetchingUser] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const { user, access_token } = useSelector((state: RootState) => state.user);
  const [avatarToUpdate, setAvatarToUpdate] = useState<File>();

  const [countries, setCountries] = useState<Country[]>([]);
  const loc = window.location.origin;

  const [valuesToUpdate, setValuesToUpdate] = useState<Partial<UserFansId>>({});

  useEffect(() => {
    countryApi.getAllCountries(loc).then((response) => setCountries(response));
  }, []);

  useEffect(() => {
    getUserFansId();
  }, [user.id]);

  useEffect(() => {
    setDisableSave(isSaving);
  }, [isSaving])

  useEffect(() => {
    if (!isFetchingFansId && !isErrorFetchingFansId) {
      console.log(user);
      if (!user.hasFansIdConfigurated) {
        navigate("/perfil/crear");
      } else {
        getUserData();
      }
    }
  }, [
    isFetchingFansId,
    isErrorFetchingFansId,
  ]);

  const getUserFansId = () => {
    setIsFetchingFansId(true);
    dispatch(
      userFansId({
        userId: user.id,
        config: {
          headers: getAuthHeaders(access_token),
        },
      })
    )
      .unwrap()
      .then(() => {
        setIsFetchingFansId(false);
      })
      .catch(() => {
        setIsFetchingFansId(false);
        setIsErrorFetchingFansId(true);
      });
  };

  const getUserData = () => {
    setIsFetchingUser(true);
    dispatch(
      getUser({
        userId: user.id,
        config: {
          headers: getAuthHeaders(access_token),
        },
      })
    )
      .unwrap()
      .then(() => {
        setIsFetchingUser(false);
      })
      .catch(() => {
        setIsFetchingUser(false);
        setIsErrorFetchingUser(true);
      });
  };

  const updateUserFansIdHandler = () => {
    if (Object.keys(valuesToUpdate).length > 0 || !!avatarToUpdate) {
      setIsSaving(true);
      dispatch(
        updateUserFansId({
          userId: user.id,
          playerId: user.player?.id,
          hasPreviousMedicalRecord: !!user.player?.medicalRecord,
          userFansId: valuesToUpdate,
          avatar: avatarToUpdate,
          config: {
            headers: getAuthHeaders(access_token),
          },
        })
      )
        .unwrap()
        .then(() => {
          setIsSaving(false);
          setIsEditing(false);
          setValuesToUpdate({});
          setAvatarToUpdate(undefined);
          setToast({
            title: "Cambios guardados con éxito",
            theme: "success",
          });
        })
        .catch(() => {
          setIsSaving(false);
          setToast({
            title:
              "Ha ocurrido un error al guardar tu información. Por favor intente nuevamente",
            theme: "error",
          });
        });
    } else {
      setIsEditing(false);
      setToast({
        title: "Cambios guardados con éxito",
        theme: "success",
      });
    }
  };

  const handleEditValue = (value: Partial<UserFansId>) => {
    let isValidValue = Object.keys(value).every(
      (key) => !!value[key as keyof Partial<UserFansId>]
    );

    if(!!value.height && value.height > 250){
      isValidValue = false;
    }

    if(!!value.weight && value.weight > 250*100){
      isValidValue = false;
    }

    setDisableSave(!isValidValue);

    setValuesToUpdate({
      ...valuesToUpdate,
      ...value,
    });
  };

  const phoneFormatted = useMemo(() => {
    const phoneCurrentValue = valuesToUpdate.phone || (user.phone as string);
    const result = phoneCurrentValue
      ? splitPhoneNumber(phoneCurrentValue)
      : { countryCode: undefined, phone: undefined };

    return result;
  }, [valuesToUpdate, user]);

  if (isFetchingFansId || isFetchingUser) 
    return <div className="h-[100vh] bg-bg-default w-full"></div>;

  return (
    <>
      <div className="text-white-pure min-h-[100vh] bg-bg-default pb-16">
        <Navbar
          isEditing={isEditing}
          onStartEditing={() => setIsEditing(true)}
          onSaveEdit={updateUserFansIdHandler}
          disableSaveEdit={disableSave}
          logoutHandler={() => navigate("/logout")}
        />
        <div className="flex flex-col md:flex-row w-full px-4 md:px-8 lg:px-16 mb-16">
          <div className="flex w-full md:w-1/3">
            <PersonalData
              username={`${user.firstName} ${user.lastName}`}
              document={user.document || "-"}
              birthday={valuesToUpdate.birthday || (user.birthday as string)}
              nationality={
                valuesToUpdate.nationality || (user.nationality as string)
              }
              countries={countries}
              phone={phoneFormatted.phone as string}
              phoneCountryCode={phoneFormatted.countryCode as string}
              email={user.email as string}
              avatar={
                !!avatarToUpdate
                  ? URL.createObjectURL(avatarToUpdate)
                  : user.media?.user_profile
              }
              isEditMode={isEditing}
              onChangeData={handleEditValue}
              onChangeAvatar={setAvatarToUpdate}
            />
          </div>
          <div className="flex flex-col w-full md:w-2/3 items-stretch">
            <div className="flex flex-col md:flex-row w-full mt-4 gap-4 p-4">
              <div className="flex flex-col w-full md:w-1/2 lg:w-3/5">
                <PhysicalData
                  weight={valuesToUpdate.weight || (user.weight as number)}
                  height={valuesToUpdate.height || (user.height as number)}
                  isEditMode={isEditing}
                  onChangeData={handleEditValue}
                />
              </div>
              <div className="flex flex-col w-full md:w-1/2 lg:w-2/5">
                <SkillfulFootData
                  skillfulFoot={
                    valuesToUpdate.skillfulFoot || (user.skillfulFoot as string)
                  }
                  isEditMode={isEditing}
                  onChangeData={handleEditValue}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-4 px-4 items-stretch">
              {user.player?.id && (<div className="flex flex-col w-full md:w-1/2 lg:w-2/5">
                <MedicalRecordData medicalRecordExp={user.player?.medicalRecordExp} medicalRecordUrl={user.player?.medicalRecord} isEditMode={isEditing} onChangeData={handleEditValue}/>
              </div>)}
              <div className={cn("flex flex-col", {"w-[calc(60%-10px)]": isXxl, "md:w-1/2 lg:w-3/5": user.player?.id, 'w-full': !isXxl && !user.player?.id})}>
                <PositionData
                  sportId={valuesToUpdate.sport || (user.sport as string)}
                  positionId={
                    valuesToUpdate.position || (user.position as string)
                  }
                  isEditMode={isEditing}
                  onChangeData={handleEditValue}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {Toast}
    </>
  );
};

export default Profile;
