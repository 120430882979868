import { useMemo, useState } from "react";
import Button from "../../../../components/Button";
import { useInput } from "../../../../components/Input";
import UniselectDropdown from "../../../../components/UniselectDropdown";
import { UniselectOption } from "../../../../components/UniselectMenu";
import { SKILLFULL_FOOT_OPTIONS } from "../../../../constants";
import { UserFansId } from "../../../../types";
import ErrorBox from "../../../../components/ErrorBox";

export type SkillfulFootStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  skillfulFootValue?: string;
};

const SkillfulFootStep = ({
  skillfulFootValue,
  title,
  setStepValues,
}: SkillfulFootStepProps) => {
  const [hasError, setHasError] = useState(false);
  const skillfullFoot = useInput<string | undefined>({
    value: skillfulFootValue,
  });

  const skillfulFootOptions: UniselectOption<string>[] =
    SKILLFULL_FOOT_OPTIONS.map((option) => {
      return {
        value: option.id,
        label: option.title,
        icon: option.icon,
        iconTheme: "light",
      };
    });

  const onContinueHandler = () => {
    if (!!skillfullFoot.value) {
      setStepValues({
        skillfulFoot: skillfullFoot.value,
      });
    } else {
      setHasError(true);
    }
  };

  const valueIcon = useMemo(() => {
    return skillfulFootOptions.find(
      (option) => option.value === skillfullFoot.value
    )?.icon;
  }, [skillfulFootOptions, skillfullFoot]);

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular mb-6">{title}</h1>
      <div className="w-full lg:w-2/3">
        <UniselectDropdown
          id="skillful-foot-dropdown"
          label=""
          value={skillfullFoot.value}
          options={skillfulFootOptions}
          onChange={(value) => {
            setHasError(!value);
            skillfullFoot.onChange(value);
          }}
          hasChanged={skillfullFoot.hasChanged}
          hasError={skillfullFoot.hasError}
          errorMsg={skillfullFoot.errorMsg}
          valueIcon={
            valueIcon as React.ForwardRefExoticComponent<
              React.SVGProps<SVGSVGElement>
            >
          }
        />
      </div>
      {hasError && (
        <div className="w-full">
          <ErrorBox message="Debe seleccionar una opción" />
        </div>
      )}
      <div className="flex justify-center items-center mt-16">
        <Button
          id={"button-skillfulFoot-step"}
          width="fit"
          onClick={onContinueHandler}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default SkillfulFootStep;
