import cn from "classnames";

export type FooterProps = {
  fixed?: boolean;
};

const Footer = ({ fixed = true }: FooterProps) => {
  return (
    <div
      className={cn(
        "z-50 flex flex-row gap-x-4 items-center w-full justify-center opacity-50 text-white-pure bg-bg-level-1 p-4",
        {
          "fixed bottom-0": fixed,
        }
      )}
    >
      <div
        className="overflow-hidden bg-cover bg-center bg-no-repeat w-4 h-4"
        style={{ backgroundImage: `url(./logo.png)` }}
      />
      <span className="text-regular text-white-pure text-center">
        Fans ID - Fansbury - Todos los derechos reservados
      </span>
    </div>
  );
};

export default Footer;
