import { configureStore } from '@reduxjs/toolkit';
import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducers from './reducers';
import { useDispatch as useOriginalDispatch } from 'react-redux';

const isProd = process.env.NODE_ENV === 'production';
const isTest = process.env.NODE_ENV === 'test';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleware: any = [thunk];

if (!isProd && !isTest) {
  middleware.push(logger);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  blacklist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: !isProd,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...middleware),
});

export const persistor = persistStore(store);

export { store };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useOriginalDispatch<AppDispatch>();
