import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserFansId } from "../types";
import { api as userApi } from "../services/user.services";
import userMapper from "../mappers/user.mapper";
import { AxiosRequestConfig } from "axios";

export type UserState = {
  access_token: string;
  user: User;
  isLogged: boolean;
};

const initialState: UserState = {
  access_token: "",
  user: {
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    hasFansIdConfigurated: false,
  },
  isLogged: false,
};

export const signIn = createAsyncThunk(
  "sign-in",
  async ({ username, password }: { username: string; password: string }) => {
    return await userApi.signIn({ username, password });
  }
);

export const userFansId = createAsyncThunk(
  "get-user-fansid",
  async ({
    userId,
    config,
  }: {
    userId: string;
    config: AxiosRequestConfig;
  }) => {
    return await userApi.getUserFansId({ userId, config });
  }
);

export const createUserFansId = createAsyncThunk(
  "create-user-fansid",
  async ({
    userId,
    userFansId,
    config,
  }: {
    userId: string;
    userFansId: UserFansId;
    config: AxiosRequestConfig;
  }) => {
    return await userApi.createUserFansId({ userId, userFansId, config });
  }
);

export const updateUserFansId = createAsyncThunk(
  "update-user-fansid",
  async ({
    userId,
    playerId,
    userFansId,
    hasPreviousMedicalRecord,
    avatar,
    config,
  }: {
    userId: string;
    playerId: string | undefined;
    userFansId: Partial<UserFansId>;
    avatar?: File;
    hasPreviousMedicalRecord: boolean;
    config: AxiosRequestConfig;
  }) => {
    const { medicalRecord, medicalRecordExp, ...fansIdDataToUpdate } = userFansId;
    if (!!avatar) {
      await userApi.loadUserAvatar({ userId, avatar, config });
    }

    const fansId = await userApi.updateUserFansId({
      userId,
      userFansId: fansIdDataToUpdate,
      config,
    });

    if(!!playerId) {
      if(medicalRecordExp) {
        await userApi.updatePlayer({ playerId, userId, medicalRecordExp, config})
      }

      if(medicalRecord) {
        if( hasPreviousMedicalRecord ) await userApi.deletePlayerMedicalRecord({userId, playerId, config})
        await userApi.createPlayerMedicalRecord({ userId, playerId, medicalRecord, config})
      }
    }

    const user = await userApi.getUser({ userId, config });

    return {
      ...userMapper.userFansIdResponseToUser(fansId),
      media: user.media,
      email: user.email,
      player: user.player,
    };
  }
);

export const getUser = createAsyncThunk(
  "get-user",
  async ({
    userId,
    config,
  }: {
    userId: string;
    config: AxiosRequestConfig;
  }) => {
    return await userApi.getUser({ userId, config });
  }
);

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout: (state) => {
      state.access_token = initialState.access_token;
      state.user = initialState.user;
      state.isLogged = initialState.isLogged;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        state.access_token = action.payload.token;
        state.user = userMapper.loginUserResponseToUser(action.payload.data);
        state.isLogged = true;
      })
      .addCase(createUserFansId.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          ...userMapper.userFansIdResponseToUser(action.payload),
        };
      })
      .addCase(updateUserFansId.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          player: action.payload.player ? {
            id: action.payload.player.id,
            medicalRecord: action.payload.player.medicalRecord,
            medicalRecordExp: action.payload.player.medicalRecordExp
          }: undefined,
          media: action.payload.media,
          email: action.payload.email,
        };
      })
      .addCase(userFansId.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          ...userMapper.userFansIdResponseToUser(action.payload),
        };
      });
  },
});

export const { logout } = users.actions;

export default users.reducer;
