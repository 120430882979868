import React from "react";
import cn from "classnames";

export type ButtonProps = {
  id: string;
  type?: "button" | "submit" | "reset";
  children: string;
  width?: "fit" | "full";
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  variant: "primary";
  loading?: boolean;
};

const Button = ({
  id,
  type,
  children,
  onClick,
  disabled,
  variant,
  loading,
  width,
}: ButtonProps): JSX.Element => {
  const variantClasses = {
    primary: cn("text-white-secondary bg-gradient-to-b from-secondary to-tertiary"),
  }[variant];

  return (
    <button
      id={`button-${id}`}
      data-testid={`button-${id}`}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={cn(
        variantClasses,
        `
        box-border
        py-2
        rounded-lg  
        flex items-center justify-center 
        disabled:cursor-not-allowed
        transition duration-100
      `,
        { "w-full": width === "full" },
        { "px-8 w-auto": width === "fit"}
      )}
    >
      <div
        className={cn("flex justify-center items-center gap-x-3")}
      >
        {loading ? (
          <div className="flex justify-center items-center space-x-4">
            <div className="animate-spin w-4 h-4 border-2 border-r-[transparent] rounded-full" />
          </div>
        ) : (
          <span
            className={cn("whitespace-nowrap", { "text-regular font-bold": variant === "primary"})}
          >
            {children}
          </span>
        )}
      </div>
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  theme: "default",
  variant: "primary",
  width: "full",
};

export default Button;
