import Button from "../../../../components/Button";
import Input, { useInput } from "../../../../components/Input";
import { UserFansId } from "../../../../types";
import { clearNumericValues } from "../../../../utils/clear-numeric-value";

export type HeightStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  heightValue?: number;
};

const HeightStep = ({ title, setStepValues, heightValue }: HeightStepProps) => {
  const height = useInput<number>({
    value: heightValue || 0,
    emptyValue: 0,
    validateError: (value) => {
      if (value > 250) {
        return [true, "Número inválido"];
      }
      return [value <= 0 || !value, "Es un campo requerido"];
    },
  });

  const onContinueHandler = () => {
    if (!height.hasError) {
      setStepValues({
        height: height.value,
      });
    }
  };

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular">{title}</h1>
      <div className="flex flex-col gap-y-4 w-full lg:w-1/2 mt-6">
        <Input
          {...height}
          id={"height"}
          name={"height"}
          type={"number"}
          placeholder={"0"}
          onKeyDown={(e) => {
            if(e.key === '.' || e.key === ','){
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const _value = clearNumericValues(e.target.value);
            const _numericValue = parseInt(_value);
            height.onChange(_numericValue);
          }}
        />
        <p className="text-regular text-white-pure">Centímetros</p>
        <div className="flex justify-center items-center mt-16">
          <Button
            id={"button-profile-step"}
            width="fit"
            onClick={onContinueHandler}
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeightStep;
