export const stringGenericFormat = (
  valueToFormat: string,
  gaps: number[],
  separator?: string
) => {
  if (!!valueToFormat) {
    const offsets = [0].concat(gaps, valueToFormat.length);
    const components = [];

    for (let i = 0; offsets[i] < valueToFormat.length; i++) {
      const start = offsets[i];
      const end = Math.min(offsets[i + 1], valueToFormat.length);
      components.push(valueToFormat.substring(start, end));
    }

    return components.join(separator || ' ');
  }

  return valueToFormat;
};
