import { MutableRefObject, useEffect } from 'react';

function useOutsideClick({
  ref,
  onClickOutside,
}: {
  ref: MutableRefObject<null | HTMLElement>;
  onClickOutside: () => void;
}): void {
  useEffect(() => {
    /* Alert if clicked on outside of element */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

export default useOutsideClick;
