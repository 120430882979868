import Toast, { ToastProps } from '../Toast';
import { useState } from 'react';

export const useToast = (
  id: string
): {
  Toast: JSX.Element | null;
  setToast: (newToast: Partial<ToastProps>) => void;
} => {
  const [show, setShow] = useState(false);
  const [toast, setToast] = useState<ToastProps>({
    id,
    theme: 'error',
    title: '',
    subtitle: '',
  });

  const _setToast = (newToast: Partial<ToastProps>) => {
    setToast({ ...toast, ...newToast });
    setShow(true);
  };

  const component = show ? (
    <Toast {...toast} onToastDestroy={() => setShow(false)} />
  ) : null;

  return { Toast: component, setToast: _setToast };
};
