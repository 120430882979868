import { useState } from "react";
import Button from "../../../../components/Button";
import { SearchCountry } from "../../../../components/SearchCountry";
import { UserFansId } from "../../../../types";
import ErrorBox from "../../../../components/ErrorBox";

export type NationalityStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  isSaving: boolean;
  nationalityValue?: string;
};

const NationalityStep = ({
  title,
  isSaving,
  setStepValues,
  nationalityValue,
}: NationalityStepProps) => {
  const [hasError, setHasError] = useState(false);
  const [selectedNationality, setSelectedNationality] = useState(
    nationalityValue || ""
  );

  const onContinueHandler = () => {
    if (!!selectedNationality) {
      setStepValues({
        nationality: selectedNationality,
      });
    } else {
      setHasError(true);
    }
  };

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular">{title}</h1>
      <div className="flex flex-col gap-y-4 w-full mt-6">
        <SearchCountry
          initialValue={selectedNationality}
          onSelected={(value) => {
            setHasError(!value);
            setSelectedNationality(value);
          }}
        />
        {hasError && (
          <div className="w-full">
            <ErrorBox message="Debe seleccionar una opción" />
          </div>
        )}
        <div className="flex justify-center items-center mt-12">
          <Button
            id={"button-profile-step"}
            width="fit"
            onClick={onContinueHandler}
            loading={isSaving}
          >
            Finalizar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NationalityStep;
