import React, { useMemo } from "react";

const FootprintLeftIcon = (
  props: React.ComponentProps<"svg"> & { theme: "dark" | "light" }
): JSX.Element => {
  const color = useMemo(
    () => (props.theme === "dark" ? "#05D88B" : "#F4F4F4"),
    [props.theme]
  );

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <style>{`.cls-1-left{fill:${color};}.cls-2-left{fill:none;stroke:${color};stroke-miterlimit:10;stroke-width:2px;}`}</style>
      </defs>
      <path
        className="cls-1-left"
        d="m58.19,5.21c6.84,0,26.66,13.9,28.71,47.39,2.05,33.5-8.2,51.72-7.29,70.64.91,18.91,9.34,35.77,9.34,46.26s-7.52,25.29-21.42,25.29c-15.27,0-23.24-10.03-23.93-19.14-.56-7.5-2.05-22.1-4.79-41.01-2.73-18.91-16.63-41.47-16.63-64.48S37.68,5.21,58.19,5.21Z"
      />
      <path
        className="cls-2-left"
        d="m141.81,5.21c-6.84,0-26.66,13.9-28.71,47.39-2.05,33.5,8.2,51.72,7.29,70.64s-9.34,35.77-9.34,46.26c0,10.48,7.52,25.29,21.42,25.29,15.27,0,23.24-10.03,23.93-19.14.56-7.5,2.05-22.1,4.79-41.01s16.63-41.47,16.63-64.48S162.32,5.21,141.81,5.21Z"
      />
    </svg>
  );
};

export default FootprintLeftIcon;
