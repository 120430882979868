import { useState } from "react";
import Button from "../../../../components/Button";
import { GenderIdType, UserFansId } from "../../../../types";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import cn from "classnames";
import {
  GENDER_TYPE_FEMALE_ID,
  GENDER_TYPE_MALE_ID,
} from "../../../../constants";
import ErrorBox from "../../../../components/ErrorBox";

export type GenderStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  genderValue?: GenderIdType;
};

const GenderStep = ({ title, setStepValues, genderValue }: GenderStepProps) => {
  const [hasError, setHasError] = useState(false);
  const [genderSelected, setGenderSelected] = useState<
    GenderIdType | undefined
  >(genderValue);

  const onContinueHandler = () => {
    if (!!genderSelected) {
      setStepValues({
        gender: genderSelected,
      });
    } else {
      setHasError(true);
    }
  };

  const onGenderSelectHandler = (genderId: GenderIdType) => {
    setHasError(false);
    setGenderSelected(genderId)
  }

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular">{title}</h1>
      <div className="flex flex-col gap-y-4 w-full justify-center items-center mt-8">
        <div className="flex flex-row justify-center items-center w-full lg:w-2/3 gap-x-4 lg:gap-x-8">
          <div
            className={cn(
              "flex flex-col w-1/2 rounded-md items-center py-8 gap-y-4 cursor-pointer",
              {
                "bg-secondary text-white-pure":
                  genderSelected === GENDER_TYPE_MALE_ID,
                "bg-bg-default text-secondary":
                  genderSelected !== GENDER_TYPE_MALE_ID,
              }
            )}
            onClick={() => onGenderSelectHandler(GENDER_TYPE_MALE_ID)}
          >
            <BsGenderMale className="h-8 w-8" />
            <p className="text-regular text-white-pure">Maculino</p>
          </div>
          <div
            className={cn(
              "flex flex-col w-1/2 rounded-md items-center py-8 gap-y-4 cursor-pointer",
              {
                "bg-secondary text-white-pure":
                  genderSelected === GENDER_TYPE_FEMALE_ID,
                "bg-bg-default text-secondary":
                  genderSelected !== GENDER_TYPE_FEMALE_ID,
              }
            )}
            onClick={() => onGenderSelectHandler(GENDER_TYPE_FEMALE_ID)}
          >
            <BsGenderFemale className="h-8 w-8" />
            <p className="text-regular text-white-pure">Femenino</p>
          </div>
        </div>
        { hasError && <div className="w-full lg:w-2/3"><ErrorBox message="Debe seleccionar una opción" /></div>}
        <div className="flex justify-center items-center mt-16">
          <Button
            id={"button-profile-step"}
            width="fit"
            onClick={onContinueHandler}
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GenderStep;
