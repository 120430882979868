import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../slices/user.slice';

const persistLocalConfig = {
  key: 'auth',
  storage,
};

const reducers = combineReducers({
  user: persistReducer(persistLocalConfig, userReducer),
});

export default reducers;
