import Input, { useInput } from "../../../../components/Input";
import { UserFansId } from "../../../../types";
import { clearNumericValues } from "../../../../utils/clear-numeric-value";

export type PhysicalDataProps = {
  height: number;
  weight: number;
  isEditMode: boolean;
  onChangeData: (value: Partial<UserFansId>) => void;
};

const PhysicalData = ({
  height,
  weight,
  isEditMode,
  onChangeData,
}: PhysicalDataProps) => {
  const heightInputValue = useInput<number>({
    value: height || 0,
    emptyValue: 0,
    validateError: (value) => {
      if (value > 250) {
        return [true, "Número inválido"];
      }
      return [value <= 0 || !value, "Es un campo requerido"];
    },
  });

  const weightInputValue = useInput<number>({
    value: (weight || 0) / 100,
    emptyValue: 0,
    validateError: (value) => {
      if (value > 250) {
        return [true, "Número inválido"];
      }
      return [value <= 0 || !value, "Es un campo requerido"];
    },
  });

  return (
    <div className="flex flex-col bg-bg-level-1 p-8 w-full rounded-md gap-y-8">
      <h1 className="text-regular font-bold text-white-pure text-left">
        Físico
      </h1>
      <div className="flex flex-col lg:flex-row w-full gap-2">
        <div className="flex flex-col w-full lg:w-1/2 p-4 border border-secondary rounded-md justify-center items-start">
          <span className="text-regular text-grey mb-2">{isEditMode ? 'Altura (cms)' : 'Altura'}</span>
          {isEditMode ? (
            <Input
              {...heightInputValue}
              id={"height"}
              name={"height"}
              type={"number"}
              placeholder={"0"}
              variant="secondary"
              onKeyDown={(e) => {
                if(e.key === '.' || e.key === ','){
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const _value = clearNumericValues(e.target.value);
                const _numericValue = parseInt(_value);
                onChangeData({ height: _numericValue });
                heightInputValue.onChange(_numericValue);
              }}
            />
          ) : (
            <span className="text-regular text-secondary font-bold">
              {height} cms
            </span>
          )}
        </div>
        <div className="flex flex-col w-full lg:w-1/2 p-4 border border-secondary rounded-md justify-center items-start">
          <span className="text-regular text-grey mb-2">{isEditMode ? 'Peso (kg)' : 'Peso'}</span>
          {isEditMode ? (
            <Input
              {...weightInputValue}
              id={"weight"}
              name={"weight"}
              type={"number"}
              placeholder={"0"}
              variant="secondary"
              onChange={(e) => {
                const _value = Math.round(100 * parseFloat(e.target.value));
                onChangeData({ weight: _value });
                weightInputValue.onChange(_value / 100);
              }}
            />
          ) : (
            <span className="text-regular text-secondary font-bold">
              {weight / 100} kg
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhysicalData;
