import httpClient from "../lib/httpClient";
import { Country } from "../types";

export const api = {
  getAllCountries: async (location: string) => {
    const { data } = await httpClient(location).get<Country[]>(
      `/data/countries/index.json`
    );

    const sortedOptions = data
      .sort((a, b) => a.name.localeCompare(b.name));

    return sortedOptions;
  },
};
