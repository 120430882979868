import React from 'react';
import cn from 'classnames';

export type UniselectOption<T> = {
  value: T;
  label: string;
  icon?: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  prefix?: JSX.Element | string;
  isSelected?: boolean;
};

export type UniselectMenuProps<T> = {
  id: string;
  options: UniselectOption<T>[];
  onChange: (value: T, label?: string) => void;
  loading?: boolean;
  loadingMsg?: string;
  absolute?: boolean;
};

function UniselectMenu<T>({
  id,
  options,
  onChange,
  loading,
  loadingMsg,
  absolute,
}: UniselectMenuProps<T>): JSX.Element {
  const boxClasses =
    'w-full h-[52px] px-4 text-regular flex items-center';

  
  return (
    <ul
      id={`uniselect-menu-${id}`}
      data-testid={`uniselect-menu-${id}`}
      className={cn(
        'mt-1 w-full max-h-52 bg-bg-default overflow-y-auto scroll-bar',
        {
          'absolute z-10': absolute,
        }
      )}
    >
      {loading ? (
        <div className={cn(boxClasses, 'animate-pulse')}>{loadingMsg}</div>
      ) : (
        options.map(
          (
            { value, label, icon: Icon, prefix, isSelected },
            i
          ) => (
            <div key={`list-option-${label}-${i}`}>
              <li
                className={cn(
                  boxClasses,
                  'hover:bg-bg-level-1 text-white-pure font-regular cursor-pointer transition duration-100',
                  {
                    'bg-gradient-to-b from-secondary to-tertiary hover:bg-tertiary font-bold': isSelected,
                  }
                )}
                onClick={() => {
                  onChange(value, label);
                }}
              >
                {prefix} {label}
                {Icon && (
                  <Icon {...{theme:`${ isSelected ? 'light': 'dark' }`}} className='text-white-pure min-w-[24px] min-h-[24px] w-6 h-6 ml-4' />
                )}
              </li>
            </div>
          )
        )
      )}
    </ul>
  );
}

export default UniselectMenu;
