import { useEffect, useState } from 'react';

export type UseWindowDimensionsReturnType = {
  width: number;
  height: number;
};

function useWindowDimensions(): UseWindowDimensionsReturnType {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    function calcWindowDimensions() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    if (window) {
      calcWindowDimensions();

      window.addEventListener('resize', calcWindowDimensions);

      return () => {
        window.removeEventListener('resize', calcWindowDimensions);
      };
    }
  }, []);

  return {
    width,
    height,
  };
}

export default useWindowDimensions;
