import axios, { AxiosError } from "axios";

const httpClient = (baseUrl?: string, validateUnAuthorized: boolean = true) => {
  //Falta agregar aca el deslogueo y que tome el token para el header
  const instance = axios.create({
    baseURL: baseUrl || process.env.REACT_APP_API_BASE_URL,
  });

  instance.interceptors.response.use(
    (config) => {
      return config;
    },
    (error) => {
      if (
        validateUnAuthorized &&
        error instanceof AxiosError &&
        error.response?.status === 401
      ) {
        window.location.replace("/logout");
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default httpClient;
