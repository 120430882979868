import Button from "../../../components/Button";

export type SaveConfirmationProps = {
  onConfirm: () => void;
};

const SaveConfirmation = ({ onConfirm }: SaveConfirmationProps) => {
  return (
    <div className="text-white-pure flex flex-col justify-center items-center pt-6 gap-y-4">
      <h1 className="text-xlarge text-secondary font-bold">¡Gracias!</h1>
      <p className="text-white-secondary text-large text-left">
        Ya cuentas con tu Fans ID y tu perfil ha sido verificado correctamente.
      </p>
      <div className="flex justify-center items-center mt-12">
        <Button
          id={"button-profile-step"}
          width="fit"
          onClick={onConfirm}
        >
          Ir al perfil
        </Button>
      </div>
    </div>
  );
};

export default SaveConfirmation;
