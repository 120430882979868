import { useEffect, useMemo, useState } from "react";
import Stepper, { StepperProps } from "../../components/Stepper";
import Layout from "../../components/Layout";
import { GenderStep, HeightStep, ProfileStep, WeightStep } from "./Steps";
import { UserFansId } from "../../types";
import NationalityStep from "./Steps/NationalityStep";
import PositionStep from "./Steps/PositionStep";
import SportStep from "./Steps/SportStep";
import SkillfulFootStep from "./Steps/SkillfulFootStep";
import BirthdayStep from "./Steps/BirthdayStep";
import cn from "classnames";
import getAuthHeaders from "../../utils/get-auth-headers";
import { createUserFansId } from "../../slices/user.slice";
import { useToast } from "../../components/Toast";
import { RootState, useDispatch } from "../../store";
import { useSelector } from "react-redux";
import SaveConfirmation from "./SaveConfirmation/SaveConfirmation";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { splitPhoneNumber } from "../../utils/split-phone-and-contry-code";

const STEPS_METADATA: StepperProps["stepsMetadata"] = [
  {
    id: 1,
    title: "PERFIL",
    desktopLabel: "1 · PERFIL",
    mobileLabel: "1",
  },
  {
    id: 2,
    title: "GÉNERO",
    desktopLabel: "2 · GÉNERO",
    mobileLabel: "2",
  },
  {
    id: 3,
    title: "FECHA DE NACIMIENTO",
    desktopLabel: "3 · FECHA NAC.",
    mobileLabel: "3",
  },
  {
    id: 4,
    title: "ALTURA",
    desktopLabel: "4 · ALTURA",
    mobileLabel: "4",
  },
  {
    id: 5,
    title: "PESO",
    desktopLabel: "5 · PESO",
    mobileLabel: "5",
  },
  {
    id: 6,
    title: "PIE HÁBIL",
    desktopLabel: "6 · PIE HÁBIL",
    mobileLabel: "6",
  },
  {
    id: 7,
    title: "DEPORTE",
    desktopLabel: "7 · DEPORTE",
    mobileLabel: "7",
  },
  {
    id: 8,
    title: "POSICIÓN",
    desktopLabel: "8 · POSICIÓN",
    mobileLabel: "8",
  },
  {
    id: 9,
    title: "NACIONALIDAD",
    desktopLabel: "9 · NACIONALIDAD",
    mobileLabel: "9",
  },
];

const CreateProfileForm = () => {
  const { Toast, setToast } = useToast("create-profile-toast");
  const { user, access_token } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [blockStepperNavigation, setBlockStepperNavigation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavedProfile, setIsSavedProfile] = useState(false);
  const [selectedStep, setSelectedStep] = useState(1);
  const [values, setValues] = useState<Partial<UserFansId>>({});
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [saveProfile, setSaveProfile] = useState(false);

  useEffect(() => {
    if (saveProfile) {
      createUserFansIdHandler();
    }
  }, [saveProfile]);

  useEffect(() => {
    if (user.hasFansIdConfigurated) {
      navigateToProfile();
    }
  }, []);

  const createUserFansIdHandler = () => {
    setIsSaving(true);
    dispatch(
      createUserFansId({
        userId: user.id,
        userFansId: {
          ...(values as UserFansId),
        },
        config: {
          headers: getAuthHeaders(access_token),
        },
      })
    )
      .unwrap()
      .then(() => {
        setIsSaving(false);
        setIsSavedProfile(true);
        setBlockStepperNavigation(true);
        setSelectedStep(selectedStep + 1);
      })
      .catch(() => {
        setIsSaving(false);
        setToast({
          title:
            "Ha ocurrido un error al guardar tu información. Por favor intente nuevamente",
          theme: "error",
        });
      });
  };

  const step = useMemo(() => {
    const stepMetadata = STEPS_METADATA.find(
      (stepMetadata) => stepMetadata.id === selectedStep
    );

    const stepTitle = stepMetadata?.title as string;
    const setStepValuesHandler = (
      stepValues: Partial<UserFansId>,
      navigateToNextStep: boolean = true
    ) => {
      setValues({
        ...values,
        ...stepValues,
      });
      setCompletedSteps([...completedSteps, selectedStep]);

      if (navigateToNextStep) {
        setSelectedStep(selectedStep + 1);
      }
    };

    const phoneFormatted = values.phone
      ? splitPhoneNumber(values.phone)
      : { countryCode: undefined, phone: undefined };

    return [
      <ProfileStep
        title={stepTitle}
        setStepValues={setStepValuesHandler}
        documentValue={values.document}
        phoneValue={phoneFormatted.countryCode}
        countryCodeValue={phoneFormatted.phone}
        stepErrorHandler={setBlockStepperNavigation}
      />,
      <GenderStep
        title={stepTitle}
        setStepValues={setStepValuesHandler}
        genderValue={values.gender}
      />,
      <BirthdayStep
        title={stepTitle}
        setStepValues={setStepValuesHandler}
        birthdayValue={values.birthday}
      />,
      <HeightStep
        title={stepTitle}
        setStepValues={setStepValuesHandler}
        heightValue={values.height}
      />,
      <WeightStep
        title={stepTitle}
        setStepValues={setStepValuesHandler}
        weightValue={values.weight}
      />,
      <SkillfulFootStep
        title={stepTitle}
        setStepValues={setStepValuesHandler}
        skillfulFootValue={values.skillfulFoot}
      />,
      <SportStep
        title={stepTitle}
        setStepValues={setStepValuesHandler}
        sportValue={values.sport}
      />,
      <PositionStep
        title={stepTitle}
        setStepValues={setStepValuesHandler}
        positionValue={values.position}
      />,
      <NationalityStep
        title={stepTitle}
        setStepValues={(values) => {
          setStepValuesHandler(values, false);
          setSaveProfile(true);
        }}
        nationalityValue={values.nationality}
        isSaving={isSaving}
      />,
    ][selectedStep - 1];
  }, [selectedStep, values]);

  const navigateToProfile = () => {
    navigate("/perfil");
  };

  return (
    <div>
      <Layout>
        <Stepper
          blockNavigation={blockStepperNavigation}
          completedSteps={completedSteps}
          stepsMetadata={STEPS_METADATA}
          onSelectStep={setSelectedStep}
          selectedStep={selectedStep}
        />
        <div
          className={cn("pt-20 lg:pt-32 lg:px-20", {
            "lg:pt-16": selectedStep === 8,
            "lg:pt-20": selectedStep === 9,
          })}
        >
          <div className="flex flex-col bg-bg-level-1 p-8 w-full rounded-md">
            {!isSavedProfile && selectedStep !== 1 && (
              <div onClick={() => setSelectedStep(selectedStep - 1)}>
                <BsArrowLeft className="h-4 w-4 text-secondary" />
              </div>
            )}
            <div className="flex flex-col w-full lg:px-32">
              {isSavedProfile ? (
                <SaveConfirmation onConfirm={navigateToProfile} />
              ) : (
                <>{step}</>
              )}
            </div>
          </div>
        </div>
      </Layout>
      {Toast}
    </div>
  );
};

export default CreateProfileForm;
