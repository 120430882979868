import Button from "../../../../components/Button";
import { useInput } from "../../../../components/Input";
import UniselectDropdown from "../../../../components/UniselectDropdown";
import { PLAYER_POSITIONS } from "../../../../constants";
import { useMemo, useState } from "react";
import { UniselectOption } from "../../../../components/UniselectMenu";
import { UserFansId } from "../../../../types";
import ErrorBox from "../../../../components/ErrorBox";

export type PositionStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  positionValue?: string;
};

const PositionStep = ({
  title,
  setStepValues,
  positionValue,
}: PositionStepProps) => {
  const [hasError, setHasError] = useState(false);
  const position = useInput<string | undefined>({
    value: positionValue,
  });

  const positions: UniselectOption<string>[] = PLAYER_POSITIONS.map(
    (position) => {
      return {
        value: position.id,
        label: position.title,
      };
    }
  );

  const selectedPosition = useMemo(
    () =>
      PLAYER_POSITIONS.find(
        (playerPosition) => playerPosition.id === position.value
      ),
    [position.value]
  );

  const onContinueHandler = () => {
    if (!!position.value) {
      setStepValues({
        position: position.value,
      });
    } else {
      setHasError(true);
    }
  };

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular mb-6">{title}</h1>
      <UniselectDropdown
        id="position-dropdown"
        label=""
        value={position.value}
        options={positions}
        onChange={(value) => {
          setHasError(!value);
          position.onChange(value);
        }}
        hasChanged={position.hasChanged}
        hasError={position.hasError}
        errorMsg={position.errorMsg}
      />
      {!!selectedPosition && (
        <div className="mt-4">
          <img
            width={400}
            height={300}
            src={`/assets/images/${selectedPosition.imageName}`}
            alt="position-image"
          />
        </div>
      )}
      {hasError && (
        <div className="w-full">
          <ErrorBox message="Debe seleccionar una opción" />
        </div>
      )}
      <div className="flex justify-center items-center mt-12">
        <Button
          id={"button-position-step"}
          width="fit"
          onClick={onContinueHandler}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default PositionStep;
