import classNames from "classnames";
import { useMemo } from "react";
import { CountryType } from "../../types";

interface SearchCountryListProps {
  search: string;
  countries: CountryType[];
  pathResource: string;
  selected: string;
  onSelect?: (country: CountryType) => void;
}

export default function SearchCountryList(props: SearchCountryListProps) {
  const { search, countries, pathResource, selected, onSelect } = props;

  const countryVisibles = useMemo(
    () =>
      search
        ? countries.filter((x) =>
            x.name.toLowerCase().startsWith(search.toLowerCase())
          )
        : countries,
    [search]
  );

  return (
    <div className="flex flex-wrap md:pl-8 lg:pl-16 xl:pl-8 2xl:pl-12">
      {countryVisibles.map((country: CountryType, index) => (
        <div
          key={index}
          className="flex flex-col justify-start items-center py-4"
        >
          <div
            className={classNames(
              "flex flex-col items-center w-24 md:w-28 lg:w-32 pt-3 pb-2 px-2 focus:outline-none",
              {
                "border border-[#05D890] rounded-lg": country.code === selected,
              }
            )}
            onClick={() => onSelect?.(country)}
          >
            <img
              className="object-scale-down w-16 h-12 border-white rounded-lg"
              src={`${pathResource}/${country.image}`}
            />
            <p className="text-white font-normal text-center mt-2">
              {country.name}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
