const WelcomeMessage = () => {
  return (
    <div className="flex flex-col gap-y-4 justify-start items-start">
      <h1 className="text-xlarge text-secondary font-bold">¡Bienvenidos a FANS ID!</h1>
      <p className="text-white-secondary text-large text-left">
        Aquí podrás verificar tu perfil como jugador, contar con toda tu
        información deportiva en un solo lugar, seguir torneos y mucho más.
      </p>
      <p className="text-white-secondary text-large text-left">
        Iniciá sesión o o regístrate con tus datos, completando un simple
        formulario.
      </p>
    </div>
  );
};

export default WelcomeMessage;
