import axios from "axios";
import React, { useEffect, useState } from "react";
import { CountryType } from "../../types";
import SearchCountryList from "./SearchCountryList";

import "./SearchCountry.css";
import Input, { useInput } from "../Input";

interface SearchCountryProps {
  initialValue?: string;
  onSelected?: (value: string) => void;
}

export default function SearchCountry({
  onSelected,
  initialValue = "",
}: SearchCountryProps) {
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [selected, setSelected] = useState(initialValue);
  const pathResource = "/data/countries";

  useEffect(() => {
    axios
      .get<CountryType[]>(`${pathResource}/index.json`)
      .then((response) =>
        setCountries(response.data.sort((a, b) => a.name.localeCompare(b.name)))
      );
  }, []);

  const onSelect = (country: CountryType) => {
    const value = !selected || selected !== country.code ? country.code : "";
    setSelected(value);
    onSelected?.(value);
  };

  const country = useInput<string>({
    value: "",
    emptyValue: "",
  });

  return (
    <div className="w-full flex flex-col gap-y-4 items-center justify-center">
      <div className="w-full lg:w-2/3">
        <Input
          {...country}
          id={"country"}
          name={"country"}
          type={"string"}
          placeholder="Buscar país"
          onChange={(e) => country.onChange(e.target.value)}
        />
      </div>

      <div className="w-full px-5 sm:px-10">
        {countries.length > 0 && (
          <div className="search-country-list h-[20rem] mbL:h-[24rem] xl:h-64 overflow-y-auto">
            <SearchCountryList
              search={country.value}
              countries={countries}
              pathResource={pathResource}
              selected={selected}
              onSelect={onSelect}
            />
          </div>
        )}
      </div>
    </div>
  );
}
