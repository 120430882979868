import { useMemo } from "react";
import { UniselectOption } from "../../../../components/UniselectMenu";
import { SKILLFULL_FOOT_OPTIONS } from "../../../../constants";
import { useInput } from "../../../../components/Input";
import UniselectDropdown from "../../../../components/UniselectDropdown";
import { UserFansId } from "../../../../types";

export type SkillfulFootDataProps = {
  skillfulFoot: string;
  isEditMode: boolean;
  onChangeData: (value: Partial<UserFansId>) => void;
};

const SkillfulFootData = ({
  skillfulFoot,
  isEditMode,
  onChangeData,
}: SkillfulFootDataProps) => {
  const skillfullFootInputValue = useInput<string | undefined>({
    value: skillfulFoot,
  });

  const skillfulFootOptions: UniselectOption<string>[] =
    SKILLFULL_FOOT_OPTIONS.map((option) => {
      return {
        value: option.id,
        label: option.title,
        icon: option.icon,
      };
    });

  const skillfulFootSelected = useMemo(() => {
    return skillfulFootOptions.find((option) => option.value === skillfulFoot);
  }, [skillfulFoot]);


  return (
    <div className="flex flex-col bg-bg-level-1 p-8 w-full h-full rounded-md gap-y-2">
      <h1 className="text-regular font-bold text-white-pure text-left">
        Pie hábil
      </h1>
      {isEditMode ? (
        <div className="w-full mt-4">
          <UniselectDropdown
            id="skillful-foot-dropdown"
            label=""
            value={skillfullFootInputValue.value}
            options={skillfulFootOptions}
            onChange={(value) => {
              onChangeData({ skillfulFoot: value});
              skillfullFootInputValue.onChange(value);
            }}
            hasChanged={skillfullFootInputValue.hasChanged}
            hasError={skillfullFootInputValue.hasError}
            errorMsg={skillfullFootInputValue.errorMsg}
            valueIcon={
              skillfulFootSelected?.icon as React.ForwardRefExoticComponent<
                React.SVGProps<SVGSVGElement>
              >
            }
          />
        </div>
      ) : (
        <>
          <span className="text-regular text-grey text-left">
            {skillfulFootSelected?.label}
          </span>
          <div className="flex flex-col justify-center items-center">
            {skillfulFootSelected?.icon && (
              <skillfulFootSelected.icon
                {...{ theme: "dark" }}
                className="h-16 w-16 text-secondary"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SkillfulFootData;
