import CreateProfileForm from "../screens/CreateProfileForm";
import Home from "../screens/Home";
import Logout from "../screens/Logout";
import Profile from "../screens/Profile";

type TRoute = {
  path: string;
  name: string;
  component: React.ComponentType;
  requireAuth: boolean;
};

const routing: TRoute[] = [
  {
    path: '/',
    name: 'home',
    component: Home,
    requireAuth: false,
  },
  {
    path: '/perfil',
    name: 'profile',
    component: Profile,
    requireAuth: true,
  },
  {
    path: '/perfil/crear',
    name: 'create-profile',
    component: CreateProfileForm,
    requireAuth: true,
  },
  {
    path:'/logout',
    name: 'logout',
    component: Logout,
    requireAuth: true,
  }
];

export default routing;