import {
  Route,
  Routes,
  redirect as Redirect,
  Navigate,
} from "react-router-dom";
import routing from "./routing";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const ProtectedRoute = ({ children }: React.PropsWithChildren) => {
  const { isLogged, user } = useSelector((state: RootState) => state.user);

  if (!isLogged) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const AppRoutes = () => {
  return (
    <Routes>
      {routing.map(({ name, path, component: Component, requireAuth }, i) => {
        if (requireAuth) {
          return (
            <Route
              key={`${name}-${i}`}
              path={path}
              element={
                <ProtectedRoute>
                  <Component />
                </ProtectedRoute>
              }
            />
          );
        }

        return (
          <Route key={`${name}-${i}`} path={path} element={<Component />} />
        );
      })}
    </Routes>
  );
};

export default AppRoutes;
