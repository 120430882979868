import cn from 'classnames';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { dateFormatted } from '../../../../utils/dateFormatted';
import { Document, Page, pdfjs} from 'react-pdf';
import { fileDownloader } from '../../../../utils/fileDownloader';
import { UserFansId } from '../../../../types';
import Input, { useInput } from "../../../../components/Input";
import { HiOutlineUpload } from "react-icons/hi"

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

export type MedicalRecordDataProps = {
  medicalRecordUrl?: string;
  medicalRecordExp?: string;
  isEditMode: boolean;
  onChangeData: (value: Partial<UserFansId>) => void;
};

const PDF_PREVIEWER_MIN_WIDTH = 90;

const MedicalRecordData = ({medicalRecordUrl, medicalRecordExp, isEditMode, onChangeData }: MedicalRecordDataProps) => {
  const [pdfPreviewWidth, setPdfPreviewWidth] = useState(PDF_PREVIEWER_MIN_WIDTH);
  
  useEffect(() => {
    if(document && pdfPreviewWidth === PDF_PREVIEWER_MIN_WIDTH){
      setPdfPreviewWidth(document.getElementById('pdf-preview')?.clientWidth ?? PDF_PREVIEWER_MIN_WIDTH)
    }
  }, [document])
  const hasMedicalRecordConfigurated = useMemo(() => medicalRecordUrl && medicalRecordExp, [medicalRecordUrl, medicalRecordExp]);

  const medicalRecordInputValue = useInput<string>({
    value: medicalRecordUrl ? 'ficha_medica.pdf' : '',
    emptyValue: '',
    validateError: (value) => {
      return [!value, "Es un campo requerido"];
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const onFileInputClick = () => {
    inputRef.current && inputRef.current?.click();
  };

  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];
    onChangeData({ medicalRecord: file});

    medicalRecordInputValue.onChange(file.name);
  };

  return (
    <div
      className={cn("flex flex-col h-full bg-bg-level-1 p-8 w-full rounded-md", {
        "gap-y-4": isEditMode,
        "gap-y-8": !isEditMode,
      })}
    >
      <h1 className="text-regular font-bold text-white-pure text-left">
        Ficha médica
      </h1>
      {isEditMode ? (
        <>
          <div className="flex flex-col">
            <span className="text-regular text-grey mb-2 text-left">
              Adjuntar
            </span>
            <div className="flex flex-row items-center justify-center gap-x-3">
              <Input
                {...medicalRecordInputValue}
                isClearable={false}
                id={"medicalRecordFile"}
                name={"medicalRecordFile"}
                type={"string"}
                placeholder={"Adjunta tu ficha medica"}
                variant="secondary"
                onChange={(e) => {
                  medicalRecordInputValue.onChange(
                    medicalRecordInputValue.value
                  );
                }}
              />
              <div
                className="p-2 border-secondary border rounded-md  bg-gradient-to-b from-secondary to-tertiary cursor-pointer"
                onClick={onFileInputClick}
              >
                <HiOutlineUpload className="h-4 w-4 text-white-pure" />
              </div>
              <input
                type="file"
                ref={inputRef}
                onChange={onUploadFile}
                size={5000}
                accept=".pdf"
                className="hidden"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-regular text-grey mb-2 text-left">
              Vencimiento
            </span>
            <span className="text-regular text-left text-grey">
              {!!medicalRecordExp
                ? dateFormatted(medicalRecordExp as string)
                : "-"}
            </span>
          </div>
        </>
      ) : (
        <div className="flex flex-col lg:flex-row w-full gap-2">
          <div
            className={cn(
              "flex flex-row w-full justify-center items-start gap-2",
              {
                "p-4 border-secondary border rounded-md":
                  hasMedicalRecordConfigurated,
              }
            )}
          >
            {hasMedicalRecordConfigurated ? (
              <>
                <div id="pdf-preview" className="flex w-1/2 bg-grey h-full items-center justify-center">
                  <div
                    className="max-[1700px]:h-[50px] min-[1701px]:h-[100px] w-full overflow-hidden flex items-start justify-start cursor-pointer"
                    onClick={async () =>
                      await fileDownloader(
                        medicalRecordUrl as string,
                        "ficha_medica.pdf"
                      )
                    }
                  >
                    <Document
                      file={medicalRecordUrl}
                      loading="Cargando..."
                      error="Error al cargar el documento"
                    >
                      <Page pageNumber={1} width={pdfPreviewWidth} />
                    </Document>
                  </div>
                </div>
                <div className="flex flex-col w-1/2 items-start justify-center">
                  <span className="text-regular text-left text-grey mb-2">
                    Adjunto
                  </span>
                  <span className="text-regular text-left text-grey">
                    {!!medicalRecordExp
                      ? dateFormatted(medicalRecordExp as string)
                      : "-"}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col w-full items-start justify-center">
                  <span className="text-regular text-left text-secondary mb-2">
                    Presiona editar para agregar tu ficha médica
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicalRecordData;
