import { useState } from 'react';
import BaseDropdown, {
  DefaultDropdownProps,
} from '../BaseDropdown/BaseDropdown';
import UniselectMenu, {
  UniselectMenuProps,
} from '../UniselectMenu/UniselectMenu';

function UniselectDropdown<T>({
  id,
  label,
  value,
  valueIcon,
  options,
  onChange,
  hasChanged,
  hasError,
  hasMsg,
  assistiveMsg,
  errorMsg,
  loading,
  loadingMsg,
  disabled,
  onBlur,
  onFocus,
}: DefaultDropdownProps<T> & UniselectMenuProps<T>): JSX.Element {
  const [showList, setShowList] = useState(false);

  return (
    <BaseDropdown
      id={`uniselect-dropdown-${id}`}
      label={label}
      value={options.find((o) => o.value === value)?.label || ''}
      hasChanged={hasChanged}
      hasError={hasError}
      hasMsg={hasMsg}
      assistiveMsg={assistiveMsg}
      errorMsg={errorMsg}
      disabled={disabled}
      showList={showList}
      setShowList={setShowList}
      onFocus={onFocus}
      onBlur={onBlur}
      valueIcon={valueIcon}
    >
      <UniselectMenu
        id={id}
        options={options.map(o => ({...o, isSelected: o.value === value }))}
        onChange={(value, label) => {
          onChange(value, label);
          setShowList(false);
        }}
        loading={loading}
        loadingMsg={loadingMsg}
        absolute={true}
      />
    </BaseDropdown>
  );
}

export default UniselectDropdown;
