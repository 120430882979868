import cn from "classnames";

export type StepMetadata = {
  id: number;
  title: string;
  desktopLabel: string;
  mobileLabel?: string;
};

export type StepProps = {
  id: StepMetadata["id"];
  title: string;
  isSelected: boolean;
  customClasses?: string;
  onSelect: (stepId: StepMetadata["id"]) => void;
};

const Step = ({ id, title, isSelected, customClasses, onSelect }: StepProps) => {
  return (
    <div
      onClick={() => onSelect(id)}
      className={cn(customClasses, "px-3 sm:px-4 py-2 flex flex-grow justify-center items-center text-medium text-white-pure cursor-pointer", {
        "!bg-secondary !border-[1px] !border-secondary": isSelected,
      })}
    >
      <span>{title}</span>
    </div>
  );
};

export default Step;
