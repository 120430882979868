import React, { useRef } from "react";
import cn from "classnames";
import { BsCaretDown, BsCaretUp } from "react-icons/bs";
import { useOutsideClick } from "../../hooks";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

export type DefaultDropdownProps<T> = {
  id: string;
  label: string;
  value?: T;
  valueIcon?: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  hasChanged: boolean;
  hasError?: boolean;
  hasMsg?: boolean;
  assistiveMsg?: string;
  errorMsg?: string;
  disabled?: boolean;
  onFocus?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onBlur?: () => void;
};

export type BaseDropdownProps = {
  // Indicates if the list is displayed
  showList: boolean;
  // Function that toggles showList state
  setShowList: (fn: (state: boolean) => boolean) => void;
  // List to be displayed on showList
  children: JSX.Element;
};

function BaseDropdown<T>({
  id,
  label,
  value,
  valueIcon: ValueIcon,
  hasError,
  hasMsg,
  assistiveMsg,
  errorMsg,
  disabled,
  showList,
  setShowList,
  hasChanged,
  children,
  onFocus,
  onBlur,
}: DefaultDropdownProps<T> & BaseDropdownProps): JSX.Element {
  const Icon = showList ? BsCaretUp : BsCaretDown;

  const wrapperRef = useRef(null);
  useOutsideClick({
    ref: wrapperRef,
    onClickOutside: () => {
      if (showList) {
        setShowList((curr: boolean) => !curr);
        onBlur?.();
      }
    },
  });

  return (
    <div id={id} data-testid={id} className="w-full relative" ref={wrapperRef}>
      {!!label && (
        <div className="flex justify-start items-center mb-2">
          <label
            className={cn(
              "text-medium font-regular overflow-hidden text-white-pure text-left"
            )}
          >
            {label}
          </label>
        </div>
      )}
      <button
        className={cn(
          "w-full outline-none h-10 px-4 bg-bg-default rounded-lg flex items-center justify-between relative group",
          { "bg-gradient-to-b from-secondary to-tertiary": !!value },
          { "border-secondary border": showList },
          { "border-error": !showList && hasChanged && hasError },
          { "cursor-not-allowed": disabled },
          { "cursor-pointer": !disabled }
        )}
        onClick={(e) => {
          setShowList((curr: boolean) => !curr);
          onFocus?.(e);
        }}
        disabled={disabled}
      >
        <div className="w-[calc(100%-40px)] flex flex-row items-center text-left text-regular text-white-pure font-bold truncate">
          {value as string}
          {ValueIcon && (
            <ValueIcon
              {...{ theme: "light" }}
              className="text-white-pure min-w-[24px] min-h-[24px] w-6 h-6 ml-4"
            />
          )}
        </div>

        <Icon className={cn("w-6 h-6", "text-white-pure")} />
      </button>

      {showList && children}

      {hasError && errorMsg && (
        <div
          className={cn(
            "flex flex-row gap-2 justify-start items-center px-2 py-1 text-medium text-left font-semibold text-white-pure sm:min-h-[32px] bg-error mt-2",
            hasError && errorMsg ? "min-h-[38px]" : "min-h-[16px]"
          )}
        >
          <div className="w-[14px] h-[14px]">
            <ExclamationTriangleIcon className="text-white-pure w-full h-full" />
          </div>
          <div className="w-[calc(100%-14px)]">
            {hasError && errorMsg ? errorMsg : assistiveMsg}
          </div>
        </div>
      )}
    </div>
  );
}

BaseDropdown.defaultProps = {
  hasMsg: true,
};

export default BaseDropdown;
