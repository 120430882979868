import { ChangeEvent, useMemo, useRef, useState } from "react";
import { Country, UserFansId } from "../../../../types";
import { calculateAge } from "../../../../utils/calculate-age";
import { documentFormatter } from "../../../../utils/document-formatter";
import { CiLocationOn } from "react-icons/ci";
import { LuCake } from "react-icons/lu";
import {
  dateFormatted,
  dateFormattedYYYYMMDD,
} from "../../../../utils/dateFormatted";
import { FiSmartphone } from "react-icons/fi";
import { HiOutlineEnvelope } from "react-icons/hi2";
import Avatar from "../../../../components/Avatar";
import Input, { useInput } from "../../../../components/Input";
import { clearNumericValues } from "../../../../utils/clear-numeric-value";
import Datepicker from "../../../../components/Datepicker";
import ErrorBox from "../../../../components/ErrorBox";
import EditNationalityModal from "./EditNationalityModal/EditNationalityModal";
import { BiEditAlt } from "react-icons/bi";
import UniselectDropdown from "../../../../components/UniselectDropdown";
import { UniselectOption } from "../../../../components/UniselectMenu";
import { PHONE_CODE_OPTIONS } from "../../../../constants";

export type PersonalDataProps = {
  username: string;
  document: string;
  birthday: string;
  nationality: string;
  countries: Country[];
  phone: string;
  phoneCountryCode: string;
  email: string;
  avatar?: string;
  isEditMode: boolean;
  onChangeData: (value: Partial<UserFansId>) => void;
  onChangeAvatar: (file: File) => void;
};

const PersonalData = ({
  username,
  document,
  birthday,
  countries,
  nationality,
  phone,
  phoneCountryCode,
  email,
  avatar,
  isEditMode,
  onChangeData,
  onChangeAvatar,
}: PersonalDataProps) => {
  const [hasPhoneError, setPhoneHasError] = useState(false);
  const [showEditNationalityModal, setShowEditNationalityModal] =
    useState(false);
  const countryNameByNationality = useMemo(() => {
    return countries.find((country) => country.code === nationality)?.name;
  }, [countries, nationality]);

  const phoneInputValue = useInput<string>({
    value: phone || "",
    emptyValue: "",
    validateError: (value) => [value.length < 1, ""],
  });

  const [hasError, setHasError] = useState(false);
  const [birthdayInputValue, setBirthdayInputValue] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: dateFormattedYYYYMMDD(birthday, "-") || null,
    endDate: dateFormattedYYYYMMDD(birthday, "-") || null,
  });

  const onChangeStringNumericInputBase = (
    value: string,
    valueMaxLength: number,
    inputOnChange: (value: string) => void
  ) => {
    const _value = clearNumericValues(value);

    if (_value.length > valueMaxLength) return;

    if (!_value.length) {
      inputOnChange("");
      return;
    }

    inputOnChange(_value);
  };

  const phoneCountryCodeInputValue = useInput<string | undefined>({
    value: phoneCountryCode,
  });

  const phoneCountryCodeOptions: UniselectOption<string>[] =
    PHONE_CODE_OPTIONS.map((option) => {
      return {
        value: option.id,
        label: option.value,
      };
    });

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className="flex flex-col gap-y-4 justify-start items-center w-full -mt-16 z-20">
        <Avatar
          id={"profile-avatar"}
          name={username}
          image={avatar}
          size="l"
          form="circular"
          isEditMode={isEditMode}
          onClickEdit={() => inputRef.current?.click()}
        />
        <span className="font-bold text-white-pure text-center text-xlarge">
          {username}
        </span>
        <div className="flex flex-col gap-y-2 mb-2 justify-center items-center">
          <span className="text-large text-grey">
            {documentFormatter(document)}
          </span>
          <span className="text-large text-secondary">
            {calculateAge(birthday)} años
          </span>
        </div>
        <div className="flex flex-col gap-y-2 justify-center items-center">
          <div className="flex flex-row justify-center items-center gap-x-2">
            <CiLocationOn className="h-5 w-5 text-secondary" />
            {isEditMode ? (
              <div
                className="w-full h-8 pr-2 rounded text-white-pure bg-gradient-to-b from-secondary to-tertiary flex flex-row justify-end items-center cursor-pointer"
                onClick={() => setShowEditNationalityModal(true)}
              >
                <span className="text-large font-bold px-6">
                  {countryNameByNationality}
                </span>
                <BiEditAlt className="h-5 w-5" />
              </div>
            ) : (
              <span className="text-large text-white-pure font-bold">
                {countryNameByNationality}
              </span>
            )}
          </div>
          <div className="flex flex-row justify-center items-center gap-x-2">
            <LuCake className="h-5 w-5 text-secondary" />
            {isEditMode ? (
              <div className="flex flex-col">
                <Datepicker
                  className="w-full"
                  value={birthdayInputValue}
                  variant="secondary"
                  onChange={(value) => {
                    const _value = value as {
                      startDate: string | null;
                      endDate: string | null;
                    };
                    setHasError(!_value.endDate);
                    setBirthdayInputValue(_value);
                    onChangeData({ birthday: _value.endDate as string });
                  }}
                />
                {hasError && (
                  <div className="w-full">
                    <ErrorBox message="Debe seleccionar una fecha" />
                  </div>
                )}
              </div>
            ) : (
              <span className="text-large text-white-pure">
                {dateFormatted(birthday)}
              </span>
            )}
          </div>
          <div className="flex flex-row justify-center items-center gap-x-2">
            <FiSmartphone className="h-5 w-5 text-secondary" />
            {isEditMode ? (
              <>
                <div className="w-2/5">
                  <UniselectDropdown
                    id="skillful-foot-dropdown"
                    label=""
                    value={phoneCountryCodeInputValue.value}
                    options={phoneCountryCodeOptions}
                    onChange={(value) => {
                      setPhoneHasError(!value);
                      const valueToChange =
                        !!phoneInputValue.value && !!value
                          ? `${value}${phoneInputValue.value}`
                          : "";
                      onChangeData({ phone: valueToChange });
                      phoneCountryCodeInputValue.onChange(value);
                    }}
                    hasChanged={phoneCountryCodeInputValue.hasChanged}
                    hasError={phoneCountryCodeInputValue.hasError}
                    errorMsg={phoneCountryCodeInputValue.errorMsg}
                  />
                </div>
                <div className="w-3/5">
                  <Input
                    {...phoneInputValue}
                    id={"phone"}
                    name={"phone"}
                    type={"string"}
                    placeholder="Teléfono"
                    variant="secondary"
                    onChange={(e) =>
                      onChangeStringNumericInputBase(
                        e.target.value,
                        25,
                        (value) => {
                          const valueToChange =
                            !!phoneCountryCodeInputValue.value && !!value
                              ? `${phoneCountryCodeInputValue.value}${value}`
                              : "";
                          onChangeData({
                            phone: valueToChange,
                          });
                          phoneInputValue.onChange(value);
                        }
                      )
                    }
                  />
                </div>
              </>
            ) : (
              <span className="text-large text-white-pure">{`${phoneCountryCode}${phone}`}</span>
            )}
          </div>
          {(hasPhoneError || phoneInputValue.hasError) && (
            <div className="w-full">
              <ErrorBox message="Es un campo requerido" />
            </div>
          )}
          <div className="flex flex-row justify-center items-center gap-x-2">
            <HiOutlineEnvelope className="h-5 w-5 text-secondary" />
            <span className="text-large text-white-pure">{email}</span>
          </div>
        </div>
      </div>
      {showEditNationalityModal && (
        <EditNationalityModal
          nationality={nationality}
          onClose={() => setShowEditNationalityModal(false)}
          onConfirm={(nationalityId: string) => {
            onChangeData({ nationality: nationalityId });
            setShowEditNationalityModal(false);
          }}
        />
      )}
      <input
        type="file"
        ref={inputRef}
        onChange={async (e: ChangeEvent<HTMLInputElement>) => {
          if (!e.target.files) return;
          onChangeAvatar(e.target.files[0]);
        }}
        accept=".png, .jpg, .jpeg"
        size={5000}
        className="hidden"
      />
    </>
  );
};

export default PersonalData;
