import cn from "classnames";
import { MdOutlineDriveFolderUpload } from "react-icons/md";

export type AvatarProps = {
  id: string;
  form: "circular" | "square";
  name: string;
  image?: string;
  size: "s" | "m" | "l";
  isEditMode: boolean;
  onClickEdit?: () => void;
};

const Avatar = ({
  id,
  form,
  image,
  name,
  size,
  isEditMode,
  onClickEdit,
}: AvatarProps): JSX.Element => {
  const firstLetter = name[0].toUpperCase();

  const formSizeClasses = {
    circular: {
      s: "min-w-[32px] min-h-[32px] w-8 h-8 rounded-full text-regular text-white-pure",
      m: "min-w-[80px] min-h-[80px] w-20 h-20 rounded-full text-xlarge text-white-pure",
      l: "min-w-[140px] min-h-[140px] w-32 h-32 rounded-full text-[40px] text-white-pure",
    },
    square: {
      s: "min-w-[40px] min-h-[40px] w-10 h-10 rounded-lg text-regular",
      m: "min-w-[80px] min-h-[80px] w-20 h-20 rounded-lg text-xlarge",
      l: "min-w-[112px] min-h-[112px] w-28 h-28 rounded-3xl text-[40px]",
    },
  }[form][size];

  return (
    <div
      id={`avatar-${id}`}
      data-testid={`avatar-${id}`}
      className={cn(
        "flex items-center justify-center",
        formSizeClasses,
        !image ? "bg-secondary" : "overflow-hidden bg-cover bg-center"
      )}
      style={{ backgroundImage: image ? `url(${image})` : undefined }}
    >
      {!image && !isEditMode && (
        <span className={`text-white`}>{firstLetter}</span>
      )}
      {isEditMode && (
        <div
          className={cn(
            formSizeClasses,
            "flex items-center justify-center bg-bg-default opacity-60 z-25 cursor-pointer"
          )}
          onClick={onClickEdit}
        >
          <MdOutlineDriveFolderUpload className="w-6 h-6 text-secondary" />
        </div>
      )}
    </div>
  );
};

Avatar.defaultProps = {
  form: "circular",
};

export default Avatar;
