import { FC } from 'react';
import cn from 'classnames';
import {
  ExclamationTriangleIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';

export type AlertProps = {
  id: string;
  title: string;
  subtitle?: string;
  height: 'standard' | 'fit',
  theme: 'error' | 'success';
};
const Alert: FC<AlertProps> = ({
  id,
  title,
  subtitle,
  height,
  theme,
}): JSX.Element => {
  const { color, Icon, iconBg, bg } = {
    error: {
      color: 'text-white-pure',
      Icon: ExclamationTriangleIcon,
      iconBg: 'bg-error',
      bg: 'bg-error',
    },
    success: {
      color: 'text-white-pure',
      Icon: CheckIcon,
      iconBg: 'bg-success',
      bg: 'bg-success',
    },
  }[theme];

  return (
    <div
      id={`alert-${id}`}
      data-testid={`alert-${id}`}
      className={cn(
        bg,
        'shadow-default flex justify-center items-center gap-x-3 py-2 px-3 transition-opacity duration-300 ease-out',
        { 'h-[60px]': height === 'standard' }
      )}
    >
      <div
        className={cn(`
          rounded-lg
          flex items-center justify-center
          cursor-pointer
          w-10 h-10 min-w-[48px] min-h-[48px]
        `,
          iconBg,
        )}
      >
        <Icon className={cn(
          color,
          'w-6 h-6'
          )} />
      </div>

      <div className='flex flex-col justify-center'>
        <p className={cn('text-body-s font-medium text-left', color)}>
          {title}
        </p>
        {subtitle && (
          <p className='text-body-s text-grey-900 font-normal text-left'>
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
};

Alert.defaultProps = {
  height: 'standard',
}

export default Alert;
