import { FiLogOut } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import { FiSave } from "react-icons/fi";
import cn from "classnames";

export type NavbarProps = {
  isEditing: boolean;
  onStartEditing: () => void;
  disableSaveEdit: boolean;
  onSaveEdit: () => void;
  logoutHandler: () => void;
};

const Navbar = ({
  isEditing,
  onStartEditing,
  onSaveEdit,
  disableSaveEdit,
  logoutHandler,
}: NavbarProps) => {
  return (
    <>
      <div
        className="overflow-hidden bg-cover bg-center bg-no-repeat w-full h-32 opacity-50"
        style={{ backgroundImage: `url(assets/images/bg-profile.png)` }}
      />
      <div className="absolute top-0 right-0 flex flex-row justify-end items-center text-white-pure w-full py-4 px-8 lg:px-32 gap-x-8 z-50">
        {isEditing ? (
          <div
            className={cn("flex flex-row justify-end items-center gap-x-2 cursor-pointer", {
              "text-grey": disableSaveEdit,
            })}
            onClick={!disableSaveEdit ? onSaveEdit : undefined}
          >
            <span className="text-regular">Guardar</span>
            <FiSave className="h-5 w-5" />
          </div>
        ) : (
          <div
            className="flex flex-row justify-end items-center gap-x-2 cursor-pointer"
            onClick={onStartEditing}
          >
            <span className="text-regular">Editar</span>
            <BiEditAlt className="h-5 w-5" />
          </div>
        )}
        <div
          className="flex flex-row justify-end items-center gap-x-2 cursor-pointer"
          onClick={logoutHandler}
        >
          <span className="text-regular">Salir</span>
          <FiLogOut className="h-5 w-5" />
        </div>
      </div>
    </>
  );
};

export default Navbar;
