import { useEffect } from "react";
import { logout } from "../../slices/user.slice";
import { useDispatch } from "../../store";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    navigate(`/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="h-[100vh] bg-bg-default w-full"></div>;
}

export default Logout;