import { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import Alert from '../Alert';

export type ToastProps = {
  id: string;
  title: string;
  subtitle?: string;
  theme: 'error' |'success';
  timer?: number;
  onToastDestroy?: () => void;
};
const Toast: FC<ToastProps> = ({
  id,
  title,
  subtitle,
  theme,
  timer,
  onToastDestroy,
}): JSX.Element => {
  const [show, setShow] = useState(true);
  const [destroyToast, setDestroyToast] = useState(false);

  useEffect(() => {
    const timerDestroy = timer ? timer + 300 : 300;
    const timerShow = setTimeout(() => {
      setShow(false);
    }, timer);
    const timerDestroyToast = setTimeout(() => {
      setDestroyToast(true);
      onToastDestroy && onToastDestroy();
    }, timerDestroy);
    return () => {
      clearTimeout(timerShow);
      clearTimeout(timerDestroyToast);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDestroy = () => {
    setShow(false);
    setTimeout(() => {
      setDestroyToast(true);
      onToastDestroy && onToastDestroy();
    }, 300);
  };

  return (
    <button
      className={cn(
        'cursor-pointer fixed z-50 w-full h-[60px] bottom-0 left-0',
        {
          'opacity-0': !show,
          hidden: destroyToast,
        }
      )}
      onClick={handleDestroy}
    >
      <Alert
        id={id}
        title={title}
        subtitle={subtitle}
        theme={theme} 
        height={'standard'}
      />
    </button>
  );
};

Toast.defaultProps = {
  timer: 3000,
};

export default Toast;
