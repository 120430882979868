import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  ChangeEvent,
} from "react";
import cn from "classnames";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useOutsideClick } from "../../hooks";
import ErrorBox from "../ErrorBox";

export type InputProps<T> = {
  id: string;
  name: string;
  alt?: string;
  type: string;
  label?: string;
  placeholder?: string;
  value?: T;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasChanged: boolean;
  hasError?: boolean;
  hasSuccess?: boolean;
  icon?: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  onIconClick?: () => void;
  hasMsg?: boolean;
  capsLockMsg?: string;
  assistiveMsg?: string;
  errorMsg?: string;
  successMsg?: string;
  disabled?: boolean;
  autoComplete?: string;
  isClearable?: boolean;
  clear?: () => void;
  step?: string;
  variant?: "primary" | "secondary";
};

function Input<T extends React.InputHTMLAttributes<HTMLInputElement>["value"]>({
  id,
  name,
  alt,
  type,
  label,
  placeholder,
  value,
  onFocus,
  onChange,
  onBlur,
  hasChanged,
  hasError,
  hasSuccess,
  capsLockMsg,
  assistiveMsg,
  errorMsg,
  successMsg,
  disabled,
  autoComplete,
  isClearable,
  step,
  clear,
  onKeyDown,
  onKeyUp,
  variant,
}: InputProps<T>): JSX.Element {
  const ref = useRef<HTMLInputElement>(null);
  const isPassword = useMemo(() => type === "password", [type]);
  const [isFocused, setIsFocused] = useState(false);
  const [capsLock, setCapsLock] = useState(false);

  const [failed, successful] = useMemo(
    () => [hasChanged && hasError, hasChanged && hasSuccess],
    [hasChanged, hasError, hasSuccess]
  );

  const message = useMemo(() => {
    if (isPassword && capsLock && capsLockMsg) return capsLockMsg;

    if (hasChanged) {
      if (hasError && errorMsg) return errorMsg;
      if (hasSuccess && successMsg) return successMsg;
    }

    return assistiveMsg;
  }, [
    isPassword,
    capsLock,
    capsLockMsg,
    hasChanged,
    hasError,
    hasSuccess,
    errorMsg,
    successMsg,
    assistiveMsg,
  ]);

  const wrapperRef = useRef(null);
  useOutsideClick({
    ref: wrapperRef,
    onClickOutside: () => setIsFocused(false),
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.onfocus = function () {
        setIsFocused(true);
      };
    }
  }, [ref]);

  const labelIsUp = useMemo(
    () =>
      !!label &&
      (!!value || isFocused || (hasChanged && (hasError || hasSuccess))),
    [hasChanged, hasError, hasSuccess, isFocused, value, label]
  );

  const showPlaceholder = useMemo(() => !labelIsUp, [labelIsUp]);

  return (
    <div className="w-full">
      {label && (
        <div className="flex justify-start items-center mb-2">
          <label
            className={cn(
              `
            text-medium
            font-regular
            overflow-hidden
            text-white-pure
            text-left
          `,
              {
                "text-success": successful,
                "text-grey-500 cursor-not-allowed": disabled,
              }
            )}
            htmlFor={name}
            onClick={() => {
              ref.current?.focus();
            }}
          >
            {label}
          </label>
        </div>
      )}
      <div
        className={cn(
          `
          w-full
          min-w-0
          relative
          inline-flex
          rounded-lg
          group
        `,
          {
            "bg-grey-200 border-grey-200 cursor-not-allowed": disabled,
            "bg-bg-default": variant === "primary",
            "bg-white-pure": variant === "secondary",
          }
        )}
        ref={wrapperRef}
      >
        <div
          className={cn(
            `
            w-full
            box-content
            cursor-text
            inline-flex
            rounded-[inherit]
            relative
            items-center
            overflow-hidden
          `
          )}
        >
          <div className="ml-4 absolute right-4 flex gap-2">
            {isClearable && !disabled && (
              <button
                className={cn(
                  `
                  w-6
                  h-6
                  min-h-[24px]
                  min-w-[24px]
                  bg-secondary
                  flex
                  justify-center
                  items-center
                  rounded-full
                  cursor-pointer
                  invisible
                  opacity-0
                  transition-all
                  duration-150
                  `,
                  {
                    "group-hover:!visible group-hover:!opacity-100": value,
                    "!visible !opacity-100": isFocused && value,
                    "bg-secondary": variant === "primary",
                    "bg-bg-default": variant === "secondary",
                  }
                )}
                onClick={() => {
                  clear?.();
                  if (isFocused) ref.current?.focus();
                }}
              >
                <XMarkIcon
                  className={cn("w-4 h-4, ", {
                    "text-primary": variant === "primary",
                    "text-white-pure": variant === "secondary",
                    "!text-error": hasError && hasChanged,
                    "!text-success-500": hasSuccess && hasChanged,
                  })}
                />
              </button>
            )}
          </div>

          <input
            id={id}
            ref={ref}
            data-testid={id}
            name={name}
            alt={alt}
            type={type}
            value={value}
            autoComplete={autoComplete}
            placeholder={placeholder}
            step={step}
            onChange={onChange}
            onKeyDown={(e) => {
              if (isPassword) setCapsLock(e.getModifierState("CapsLock"));
              onKeyDown?.(e);
            }}
            onKeyUp={(e) => {
              if (isPassword) setCapsLock(e.getModifierState("CapsLock"));
              onKeyUp?.(e);
            }}
            onFocus={(e) => {
              onFocus?.(e);
            }}
            onBlur={(e) => {
              if (isPassword) setCapsLock(false);
              if (!value)
                onChange?.({
                  target: { value: "" },
                } as ChangeEvent<HTMLInputElement>);
              onBlur?.(e);
            }}
            disabled={disabled}
            className={cn(
              `
              text-regular
              box-content
              block
              min-w-0
              outline-none
              px-4
              appearance-none
              h-10
              w-full
            `,
              {
                "text-white-pure placeholder:text-white-pure": variant === "primary",
                "text-primary placeholder:text-primary font-bold": variant === "secondary",
                "text-grey-500 cursor-not-allowed": disabled,
              }
            )}
            style={{ background: "none" }}
          />
          <fieldset
            aria-hidden="true"
            className={cn(
              `
              border
              absolute
              px-2
              rounded-[inherit]
              overflow-hidden
              min-w-0
              text-left
              w-full
              pointer-events-none
            `,
              {
                "border-secondary": isFocused && !failed && !successful,
                "group-hover:border-primary border-primary":
                  !isFocused && !disabled && !failed && !successful,
                "border-error": failed,
                "border-success": successful,
                "border-grey-300 cursor-not-allowed": disabled,
              }
            )}
            style={{ inset: "-5px 0px 0px" }}
          >
            <legend
              className={cn(
                `
                overflow-hidden
                display-block
                w-auto
                h-3
                ml-2
                max-w-full
                whitespace-nowrap
                font-regular
                text-medium
              `
              )}
            />
          </fieldset>
        </div>
      </div>
      {failed && message && <ErrorBox message={message} />}
    </div>
  );
}

Input.defaultProps = {
  hasMsg: true,
  autoComplete: "off",
  isClearable: true,
  variant: "primary",
};

export default Input;
