import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import ModalBackground from '../ModalBackground';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useOutsideClick } from '../../hooks';

export type ModalProps = {
  id: string;
  body: JSX.Element;
  onClose: () => void;
  closeOnBackdropClick?: boolean;
  onBackdropClose?: () => void;
  size?: 'fit' | 'full';
};

const Modal = ({
  id,
  body,
  onClose,
  closeOnBackdropClick,
  onBackdropClose,
  size,
}: ModalProps): JSX.Element | null => {
  const wrapperRef = useRef(null);
  useOutsideClick({
    ref: wrapperRef,
    onClickOutside: () => {
      if (closeOnBackdropClick) {
        onBackdropClose ? onBackdropClose() : onClose();
      }
    },
  });

  useEffect(() => {
    window?.document.body.classList.add('opened-modal');

    return () => {
      window?.document.body.classList.remove('opened-modal');
    };
  }, []);

  return (
    <ModalBackground id={id}>
      <div
        id={`modal-${id}`}
        data-testid={`modal-${id}`}
        className={cn(
          {
            'w-full max-w-[800px]': size !== 'fit',
            'w-fit': size === 'fit',
          },
          'm-8 bg-bg-level-1 rounded-lg shadow-default p-10'
        )}
        ref={wrapperRef}
      >
        <div className='flex flex-col items-center justify-center'>
          <div className=' box-border w-full sm:px-4 py-2 sm:py-2 flex items-center justify-end'>
            <button onClick={onClose}>
              <XMarkIcon className='w-6 h-6' />
            </button>
          </div>

          <div
            className={'flex flex-col items-center justify-center w-full'}
          >
            {body}
          </div>
        </div>
      </div>
    </ModalBackground>
  );
};

Modal.defaultProps = {
  size: 'full',
};

export default Modal;
