import Button from "../../../components/Button";
import Input, { useInput } from "../../../components/Input";

export type LoginFormProps = {
  handleSignIn: (username: string, password: string) => Promise<void>;
  isLoading: boolean;
};

const LoginForm = ({ handleSignIn, isLoading }: LoginFormProps) => {
  const username = useInput<string>({
    value: "",
    emptyValue: "",
    validateError: (value) => [
      value.length < 1,
      "Es un campo requerido",
    ],
  });

  const password = useInput<string>({
    value: "",
    emptyValue: "",
    validateError: (value: string | any[]) => [value.length < 1, "Es un campo requerido"],
  });

  const onClickSignInHandler = () => {
    if (!username.hasError && !password.hasError) {
      handleSignIn(username.value, password.value);
    }
  };

  return (
    <div className="flex flex-col w-full bg-bg-level-1 px-8 lg:px-20 py-10 opacity-80 rounded-md">
      <div className="flex flex-col gap-4 mb-4">
        <Input
          {...username}
          id={"username"}
          name={"username"}
          label={"Usuario o email *"}
          type={"string"}
          placeholder="Usuario o email"
          onChange={(e) => username.onChange(e.target.value)}
        />
        <Input
          {...password}
          id={"password"}
          name={"password"}
          label={"Contraseña *"}
          type={"password"}
          placeholder="Contraseña"
          onChange={(e) => password.onChange(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2 mb-4">
        <p className="text-white-secondary text-medium text-center italic">
          * Campos obligatorios
        </p>
        <p className="text-white-secondary text-medium text-center italic">
          Si olvidaste tu contraseña, contactate con la app de tu torneo para
          recuperarla antes de seguir.
        </p>
      </div>
      <div className="flex justify-center items-center">
        <Button
          id={"button-test"}
          width="fit"
          loading={isLoading}
          onClick={onClickSignInHandler}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
