import { useState } from "react";
import Datepicker from "../../../../components/Datepicker";
import Button from "../../../../components/Button";
import { UserFansId } from "../../../../types";
import ErrorBox from "../../../../components/ErrorBox";

export type BirthdayStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  birthdayValue?: string;
};

const BirthdayStep = ({ title, setStepValues, birthdayValue }: BirthdayStepProps) => {
  const [hasError, setHasError] = useState(false);
  const [birthday, setBirthday] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: birthdayValue || null,
    endDate: birthdayValue || null,
  });

  const onContinueHandler = () => {
    if (!!birthday.endDate && !!birthday.startDate) {
      setStepValues({ birthday: birthday.startDate as string });
    } else {
      setHasError(true);
    }
  };

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular">{title}</h1>
      <Datepicker
        className="w-full lg:w-1/2 mt-8"
        value={birthday}
        onChange={(value) => {
          setHasError(!value);
          setBirthday(value);
        }}
      />
      {hasError && (
        <div className="w-full lg:w-1/2">
          <ErrorBox message="Debe seleccionar una fecha" />
        </div>
      )}
      <div className="flex justify-center items-center mt-16">
        <Button
          id={"button-profile-step"}
          width="fit"
          onClick={onContinueHandler}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default BirthdayStep;
