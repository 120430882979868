import Button from "../../../../components/Button";
import Input, { useInput } from "../../../../components/Input";
import { UserFansId } from "../../../../types";

export type WeightStepProps = {
  title: string;
  setStepValues: (values: Partial<UserFansId>) => void;
  weightValue?: number;
};

const WeightStep = ({ title, setStepValues, weightValue }: WeightStepProps) => {
  const weight = useInput<number>({
    value: (weightValue || 0) / 100,
    emptyValue: 0,
    validateError: (value) => {
      if (value > 250) {
        return [true, "Número inválido"];
      }
      return [value <= 0 || !value, "Es un campo requerido"];
    },
  });

  const onContinueHandler = () => {
    if (!weight.hasError) {
      setStepValues({
        weight: weight.value * 100,
      });
    }
  };

  return (
    <div className="text-white-pure flex flex-col justify-center items-center">
      <h1 className="text-regular">{title}</h1>
      <div className="flex flex-col gap-y-4 w-full lg:w-1/2 mt-6">
        <Input
          {...weight}
          id={"weight"}
          name={"weight"}
          type={"number"}
          placeholder={"0"}
          onChange={(e) =>
            weight.onChange(Math.round(100 * parseFloat(e.target.value)) / 100)
          }
        />
        <p className="text-regular text-white-pure">KG</p>
        <div className="flex justify-center items-center mt-16">
          <Button
            id={"button-profile-step"}
            width="fit"
            onClick={onContinueHandler}
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WeightStep;
